import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'chat',
  initialState: { loading: false },
  reducers: {
    
    // CHATGROUP REDUCER FUNCTION

    // CHATGROUP FETCH LIST
    fetchChatChatgroupListStart: stateLoadingStart,
    fetchChatChatgroupListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `chatgroup_list`),
    fetchChatChatgroupListFailure: stateError,

    // CHATGROUP FETCH ITEM
    fetchChatChatgroupItemStart: stateLoadingStart,
    fetchChatChatgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `chatgroup_item`),
    fetchChatChatgroupItemFailure: stateError,

    // CHATGROUP CREATE ITEM
    createChatChatgroupItemStart: stateLoadingStart,
    createChatChatgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `chatgroup_item`),
    createChatChatgroupItemFailure: stateError,

    // CHATGROUP UPDATE ITEM
    updateChatChatgroupItemStart: stateLoadingStart,
    updateChatChatgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `chatgroup_item`),
    updateChatChatgroupItemFailure: stateError,

    // CHATGROUP DELETE ITEM
    deleteChatChatgroupItemStart: stateLoadingStart,
    deleteChatChatgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_chatgroup`),
    deleteChatChatgroupItemFailure: stateError,


    // MESSAGE REDUCER FUNCTION

    // MESSAGE FETCH LIST
    fetchChatMessageListStart: stateLoadingStart,
    fetchChatMessageListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_list`),
    fetchChatMessageListFailure: stateError,

    // MESSAGE FETCH ITEM
    fetchChatMessageItemStart: stateLoadingStart,
    fetchChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    fetchChatMessageItemFailure: stateError,

    // MESSAGE CREATE ITEM
    createChatMessageItemStart: stateLoadingStart,
    createChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    createChatMessageItemFailure: stateError,

    // MESSAGE UPDATE ITEM
    updateChatMessageItemStart: stateLoadingStart,
    updateChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    updateChatMessageItemFailure: stateError,

    // MESSAGE DELETE ITEM
    deleteChatMessageItemStart: stateLoadingStart,
    deleteChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_message`),
    deleteChatMessageItemFailure: stateError,


  },
});

export const {
  fetchChatChatgroupListStart, fetchChatMessageListStart,
  fetchChatChatgroupListSuccess, fetchChatMessageListSuccess,
  fetchChatChatgroupListFailure, fetchChatMessageListFailure,
  fetchChatChatgroupItemStart, fetchChatMessageItemStart,
  fetchChatChatgroupItemSuccess, fetchChatMessageItemSuccess,
  fetchChatChatgroupItemFailure, fetchChatMessageItemFailure,
  createChatChatgroupItemStart, createChatMessageItemStart,
  createChatChatgroupItemSuccess, createChatMessageItemSuccess,
  createChatChatgroupItemFailure, createChatMessageItemFailure,
  updateChatChatgroupItemStart, updateChatMessageItemStart,
  updateChatChatgroupItemSuccess, updateChatMessageItemSuccess,
  updateChatChatgroupItemFailure, updateChatMessageItemFailure,
  deleteChatChatgroupItemStart, deleteChatMessageItemStart,
  deleteChatChatgroupItemSuccess, deleteChatMessageItemSuccess,
  deleteChatChatgroupItemFailure, deleteChatMessageItemFailure
} = slice.actions;

export default slice.reducer;
