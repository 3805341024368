import React, { useState, useEffect, lazy } from 'react';
import axios from 'axios';
import LayoutSelector from './LayoutSelector';
import ReactDOMServer from 'react-dom/server';
import { initialTemplateData } from '../data/initialTemplateData';
import { categories } from '../data/categories';

function DynamicForm({ data, onChange, createTemplates, updateTemplates }) {
  const [inputStyle, setInputStyle] = useState({
    padding: '8px',
    marginBottom: '4px',
    fontSize: '12px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '90%',
  });

  const handleInputChange = (field, index, value) => {
    const newData = { ...data };
    if (Array.isArray(newData[field])) {
      newData[field][index] = value;
    } else {
      newData[field] = value;
    }
    onChange(newData);
  };

  const handleAddStringEntry = (field) => {
    const newData = { ...data };
    if (Array.isArray(newData[field])) {
      newData[field].push('');
    } else {
      newData[field] = [''];
    }
    onChange(newData);
  };

  const handleAddObjectEntry = (field) => {
    const newData = { ...data };
    if (Array.isArray(newData[field])) {
      const newObject = {};
      if (newData[field].length > 0 && typeof newData[field][0] === 'object') {
        Object.keys(newData[field][0]).forEach((key) => {
          newObject[key] = '';
        });
      }
      newData[field].push(newObject);
    } else {
      newData[field] = [{}];
    }
    onChange(newData);
  };

  const handleRemoveEntry = (field, index) => {
    const newData = { ...data };
    if (Array.isArray(newData[field])) {
      newData[field].splice(index, 1);
    }
    onChange(newData);
  };

  return (
    <div>
      <form style={{ marginBottom: 100}}>
        <h3>Email Information</h3>
        {data.messages && (
          <div style={{ marginBottom: '20px' }}>
            <h4>Text Information</h4>
            {data.messages.map((message, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <input
                  type="text"
                  value={message}
                  onChange={(e) => handleInputChange('messages', index, e.target.value)}
                  style={inputStyle}
                />
              </div>
            ))}
          </div>
        )}
        {data.buttons && (
          <div style={{ marginBottom: '20px' }}>
            <h4>Buttons</h4>
            {data.buttons.map((button, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="Label"
                  value={button.label}
                  onChange={(e) =>
                    handleInputChange('buttons', index, { ...button, label: e.target.value })
                  }
                  style={{ ...inputStyle, marginRight: '10px' }}
                />
                <input
                  type="text"
                  placeholder="Link"
                  value={button.link}
                  onChange={(e) =>
                    handleInputChange('buttons', index, { ...button, link: e.target.value })
                  }
                  style={{ ...inputStyle}}
                />
              </div>
            ))}
          </div>
        )}
        {data.data && (
          <div style={{ marginBottom: '20px' }}>
            <h3>Data</h3>
            {data.data.map((item, index) => (
              <div key={index} style={{display: 'flex'}}>
              <div style={{}}>
                {typeof item === 'string' ? (
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handleInputChange('data', index, e.target.value)}
                    style={inputStyle}
                  />
                ) : (
                  Object.entries(item).map(([key, value]) => (
                    <div key={key} style={{ marginBottom: '8px' }}>
                      <label>{key}</label>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) =>
                          handleInputChange('data', index, { ...item, [key]: e.target.value })
                        }
                        style={{ ...inputStyle, marginRight: '10px' }}
                      />
                    </div>
                  ))
                )}
              </div>
              <button
                type="button"
                onClick={() => handleRemoveEntry('data', index)}
                style={{
                  padding: '8px',
                  backgroundColor: '#dc3545',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginBottom: '8px',
                  fontSize: 12,
                  maxHeight: 30
                }}
              >
                Remove
              </button>
              </div>
            ))}
            {data.data.length > 0 && typeof data.data[0] === 'string' && (
              <button
                type="button"
                onClick={() => handleAddStringEntry('data')}
                style={{
                  padding: '8px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginRight: '10px',
                  fontSize: 12
                }}
              >
                Add Entry
              </button>
            )}
            {data.data.length > 0 && typeof data.data[0] === 'object' && (
              <button
                type="button"
                onClick={() => handleAddObjectEntry('data')}
                style={{
                  padding: '8px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginRight: '10px',
                  fontSize: 12
                }}
              >
                Add Object
              </button>
            )}
          </div>
        )}
        {data.total && (
          <div style={{ marginBottom: '20px' }}>
            <h3>Total</h3>
            <input
              type="text"
              value={data.total}
              onChange={(e) => handleInputChange('total', null, e.target.value)}
              style={inputStyle}
            />
          </div>
        )}
      </form>
      {createTemplates && <button style={{backgroundColor: '#007BFF', color: 'white', border: 'none', width: '150px', padding: 10, position: 'absolute', bottom: 0, marginBottom: 10, cursor: 'pointer'}}>Create My Templates</button>}
      {updateTemplates && <button style={{backgroundColor: '#007BFF', color: 'white', border: 'none', width: '150px', padding: 10, position: 'absolute', bottom: 0, marginBottom: 10, cursor: 'pointer'}}>Update</button>}
    </div>
  );
}

export default DynamicForm;
