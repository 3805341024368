import React, { useState, useEffect } from 'react';

const tagOptions = {
  container: ['div', 'span', 'section', 'article', 'header', 'footer', 'main', 'nav', 'aside'],
  list: ['ul', 'ol', 'li', 'dl', 'dt', 'dd'],
  link: ['a'],
  multimedia: ['img', 'audio', 'video', 'picture', 'source', 'track'],
  table: ['table', 'thead', 'tr', 'tbody', 'td', 'tfoot', 'th', 'caption', 'col', 'colgroup'],
  other: ['iframe', 'form', 'input', 'button', 'select', 'option', 'textarea', 'label'],
  typography: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'code', 'strong', 'em', 'br', 'hr'],
};

const HtmlAnalyzer = ({ initialHtml }) => {
  const [htmlInput, setHtmlInput] = useState('');
  const [parsedElements, setParsedElements] = useState([]);
  const [formData, setFormData] = useState({});
  const [newTagType, setNewTagType] = useState('');
  const [selectedParentId, setSelectedParentId] = useState('');
  const [newTagAttributes, setNewTagAttributes] = useState('');

  useEffect(() => {
    setHtmlInput(initialHtml);
  }, [initialHtml]);

  useEffect(() => {
    setHtmlInput(getUpdatedHtml(parsedElements) || '<div></div>');
  }, [formData, parsedElements]);

  const parseHtml = (element) => {
    const processElement = (el) => {
      if (el.nodeType === 1) {
        const tagName = el.tagName.toLowerCase();
        const attributes = [...el.attributes].map(attr => ({ name: attr.name, value: attr.value }));
        const content = el.innerHTML;

        const children = [];
        el.childNodes.forEach(child => {
          if (child.nodeType === 1) {
            children.push(processElement(child));
          }
        });

        return {
          tag: tagName,
          attributes,
          content: children.length > 0 ? children : el.textContent,
          id: Math.random().toString(36).substr(2, 9),
        };
      }
      return null;
    };

    return Array.from(element.children).map(processElement).filter(el => el !== null);
  };

  const handleInputChange = (e) => {
    setHtmlInput(e.target.value);
  };

  const handleFormChange = (id, type, newValue) => {
    const updatedFormData = { ...formData };

    if (type.startsWith('attributes.')) {
      const attributeName = type.split('.')[1];
      updatedFormData[id] = {
        ...updatedFormData[id],
        attributes: {
          ...updatedFormData[id]?.attributes,
          [attributeName]: newValue,
        },
      };
    } else {
      updatedFormData[id] = {
        ...updatedFormData[id],
        content: newValue,
      };
    }

    setFormData(updatedFormData);
  };

  const parseAndAnalyzeHtml = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlInput, 'text/html');
    const elements = parseHtml(doc.body);
    setParsedElements(elements);

    const initialFormData = {};
    const collectFormData = (elements) => {
      elements.forEach((el) => {
        initialFormData[el.id] = {
          content: typeof el.content === 'string' ? el.content : '',
          attributes: el.attributes.reduce((acc, attr) => ({ ...acc, [attr.name]: attr.value }), {}),
        };
        if (Array.isArray(el.content)) {
          collectFormData(el.content);
        }
      });
    };

    collectFormData(elements);
    setFormData(initialFormData);
  };

  const addNewTag = () => {
    if (!newTagType || !selectedParentId) return;

    const attributesArray = newTagAttributes.split(',').map(attr => ({
      name: attr.trim(),
      value: '',
    }));

    const newElement = {
      tag: newTagType,
      attributes: attributesArray.filter(attr => attr.name),
      content: '',
      id: Math.random().toString(36).substr(2, 9),
    };

    const updatedElements = [...parsedElements];
    const findAndAddChild = (elements) => {
      elements.forEach((el) => {
        if (el.id === selectedParentId) {
          if (!Array.isArray(el.content)) {
            el.content = [];
          }
          el.content.push(newElement);
        } else if (Array.isArray(el.content)) {
          findAndAddChild(el.content);
        }
      });
    };

    findAndAddChild(updatedElements);
    setParsedElements(updatedElements);

    // Resetting form fields
    setFormData({
      ...formData,
      [newElement.id]: { content: '', attributes: {} },
    });
    setNewTagType('');
    setSelectedParentId('');
    setNewTagAttributes('');
  };

  const removeTag = (id, parentElements) => {
    const filterElements = (elements) => {
      return elements
        .filter((el) => el.id !== id)
        .map((el) => ({
          ...el,
          content: Array.isArray(el.content) ? filterElements(el.content) : el.content,
        }));
    };

    const updatedElements = filterElements(parentElements);
    setParsedElements(updatedElements);

    const updatedFormData = { ...formData };
    delete updatedFormData[id];
    setFormData(updatedFormData);
  };

  const renderFormFields = (elements) => {
    return elements.map((el) => (
      <div key={el.id} style={{ marginLeft: '20px', marginBottom: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
        <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>{el.tag.toUpperCase()} Content:</label>
        {el.attributes.map(attr => (
          <div key={attr.name} style={{ marginLeft: '10px', marginBottom: '5px' }}>
            <label style={{ marginRight: '5px' }}>{attr.name.toUpperCase()}:</label>
            <input
              type="text"
              style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '3px' }}
              value={formData[el.id]?.attributes[attr.name] || ''}
              onChange={(e) => handleFormChange(el.id, `attributes.${attr.name}`, e.target.value)}
            />
          </div>
        ))}
        {Array.isArray(el.content) ? (
          renderFormFields(el.content)
        ) : (
          <textarea
            rows="3"
            cols="40"
            style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '3px' }}
            value={formData[el.id]?.content || ''}
            onChange={(e) => handleFormChange(el.id, 'content', e.target.value)}
          ></textarea>
        )}
        <button
          onClick={() => removeTag(el.id, parsedElements)}
          style={{ marginTop: '10px', padding: '5px 10px', backgroundColor: '#e74c3c', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}
        >
          Remove {el.tag.toUpperCase()}
        </button>
      </div>
    ));
  };

  const renderParentOptions = (elements, depth = 0) => {
    return elements.map((el) => (
      <React.Fragment key={el.id}>
        <option value={el.id}>{'—'.repeat(depth)} {el.tag.toUpperCase()}</option>
        {Array.isArray(el.content) && renderParentOptions(el.content, depth + 1)}
      </React.Fragment>
    ));
  };

  const getUpdatedHtml = (elements) => {
    const generateHtml = (els) => {
      return els.map((el) => {
        const attributes = el.attributes
          .map(attr => `${attr.name}="${formData[el.id]?.attributes[attr.name] || attr.value}"`)
          .join(' ');

        const content = Array.isArray(el.content)
          ? generateHtml(el.content)
          : formData[el.id]?.content || el.content;

        return `<${el.tag} ${attributes}>${content}</${el.tag}>`;
      }).join('');
    };

    return generateHtml(elements);
  };

  return (
    <div style={{ display: 'flex', gap: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ flex: 1 }}>
        <h3>HTML Input</h3>
        <textarea
          rows="10"
          cols="50"
          style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
          value={htmlInput}
          onChange={handleInputChange}
          placeholder="Paste your HTML code here..."
        ></textarea>
        <br />
        <button
          onClick={parseAndAnalyzeHtml}
          style={{ marginTop: '10px', padding: '8px 15px', backgroundColor: '#3498db', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}
        >
          Analyze HTML
        </button>
      </div>

      <div style={{ flex: 1 }}>
        <h3>Form to Update Tags</h3>
        {renderFormFields(parsedElements)}
      </div>

      <div style={{ flex: 1 }}>
        <h3>Add New Tag</h3>
        <select
          value={newTagType}
          onChange={(e) => setNewTagType(e.target.value)}
          style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '5px', width: '100%' }}
        >
          <option value="">Select a tag...</option>
          {Object.entries(tagOptions).map(([category, tags]) => (
            <optgroup key={category} label={category.toUpperCase()}>
              {tags.map(tag => (
                <option key={tag} value={tag}>{tag.toUpperCase()}</option>
              ))}
            </optgroup>
          ))}
        </select>
        <br />
        <label style={{ marginTop: '10px', display: 'block' }}>Select Parent Tag:</label>
        <select
          value={selectedParentId}
          onChange={(e) => setSelectedParentId(e.target.value)}
          style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '5px', width: '100%' }}
        >
          <option value="">Select a parent tag...</option>
          {renderParentOptions(parsedElements)}
        </select>
        <br />
        <label style={{ marginTop: '10px', display: 'block' }}>Enter Attributes (comma-separated):</label>
        <input
          type="text"
          style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '5px', width: '100%' }}
          value={newTagAttributes}
          onChange={(e) => setNewTagAttributes(e.target.value)}
        />
        <br />
        <button
          onClick={addNewTag}
          style={{ marginTop: '10px', padding: '8px 15px', backgroundColor: '#2ecc71', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}
        >
          Add Tag
        </button>
      </div>

      <div style={{ flex: 1 }}>
        <h3>Live HTML Preview</h3>
        <div
          style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}
          dangerouslySetInnerHTML={{ __html: htmlInput }}
        ></div>
      </div>
    </div>
  );
};

const emailTemplates = [
  {
    id: 'template1',
    name: 'Subscription Renewal Notice',
    html: `<div style="font-family: Arial, sans-serif; color: #333; padding: 20px;">
  <h2 style="color: #2c3e50;">Subscription Renewal Notice</h2>
  <p>Dear [Customer Name],</p>
  <p>Your subscription for [Service Name] is due for renewal on [Date]. Please renew your subscription to continue enjoying our services without interruption.</p>
  <ul>
    <li>Plan: [Plan Name]</li>
    <li>Price: [Price]</li>
    <li>Renewal Date: [Date]</li>
  </ul>
  <p>If you have any questions, feel free to <a href="mailto:support@company.com" style="color: #3498db;">contact us</a>.</p>
  <p>Best regards,<br>[Company Name]</p>
  <footer style="border-top: 1px solid #ccc; padding-top: 10px;">
    <p style="font-size: 12px; color: #999;">[Company Name], [Address], [City, State, Zip]</p>
  </footer>
</div>
`
  },
  {
    id: 'template2',
    name: 'Password Reset Request',
    html: `<div style="font-family: Arial, sans-serif; color: #333; padding: 20px;">
  <h2 style="color: #2c3e50;">Password Reset Request</h2>
  <p>Dear [User Name],</p>
  <p>We received a request to reset your password for your account. Please click the button below to reset your password:</p>
  <a href="[Reset Link]" style="background-color: #3498db; color: white; padding: 10px 15px; text-decoration: none;">Reset Password</a>
  <p>If you did not request a password reset, please ignore this email or <a href="mailto:support@company.com" style="color: #3498db;">contact support</a>.</p>
  <p>Best regards,<br>[Company Name]</p>
  <footer style="border-top: 1px solid #ccc; padding-top: 10px;">
    <p style="font-size: 12px; color: #999;">[Company Name], [Address], [City, State, Zip]</p>
  </footer>
</div>
`
  },
  {
    id: 'template3',
    name: 'Welcome Email for New Users',
    html: `<div style="font-family: Arial, sans-serif; color: #333; padding: 20px;">
  <h2 style="color: #2c3e50;">Welcome to [Company Name]!</h2>
  <p>Dear [User Name],</p>
  <p>We are thrilled to have you join us! You can start exploring our services right away.</p>
  <p>Here's what you can do next:</p>
  <ul>
    <li>Complete your profile</li>
    <li>Explore our features</li>
    <li>Get in touch with our support team if you need help</li>
  </ul>
  <p>If you have any questions, feel free to <a href="mailto:support@company.com" style="color: #3498db;">reach out</a>.</p>
  <p>Best regards,<br>[Company Name]</p>
  <footer style="border-top: 1px solid #ccc; padding-top: 10px;">
    <p style="font-size: 12px; color: #999;">[Company Name], [Address], [City, State, Zip]</p>
  </footer>
</div>
`
  },
];

const EmailTemplateEditor = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(emailTemplates[0].html);

  const handleTemplateChange = (e) => {
    const selected = emailTemplates.find(template => template.id === e.target.value);
    setSelectedTemplate(selected.html);
  };

  return (
    <div>
      <h3>Select an Email Template</h3>
      <select onChange={handleTemplateChange}>
        {emailTemplates.map(template => (
          <option key={template.id} value={template.id}>
            {template.name}
          </option>
        ))}
      </select>

      <HtmlAnalyzer initialHtml={selectedTemplate} />
    </div>
  );
};

export default EmailTemplateEditor;

// export default HtmlAnalyzer;
