import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { createUserToken } from "services/api-data/src/utils/auth";
import CaptchaVerification from "components/captcha";
import { utils } from "services/api-data/src/App";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";

const LoginForm = ({formData, setFormData, captchaVerify, setCaptchaVerify, loading, handleLogin}) => {
  const navigate = useNavigate()
  return(
    <small>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Login</h2>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontSize: 12,display: 'block', marginBottom: '8px', color: '#555' }}>Email</label>
        <input
            type="text"
            placeholder="Enter email"
            value={formData?.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
            required
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontSize: 12,display: 'block', marginBottom: '8px', color: '#555' }}>Password</label>
        <input
            type="password"
            placeholder="Enter password"
            value={formData?.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            required
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
        />
      </div>
      <CaptchaVerification {...{ captchaVerify, setCaptchaVerify }} />
      <button
        type="submit"
        disabled={!captchaVerify || loading}
        onClick={handleLogin}
        style={{ width: '100%', padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: (!captchaVerify || loading) ? '#ccc' : '#007bff', color: '#fff', fontSize: '14px', cursor: (!captchaVerify || loading) ? 'not-allowed':'pointer' }}
      >
        Login
      </button>
      <div style={{marginTop: 10}}>Don't have account? <button style={{backgroundColor:'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', color: '#007BFF'}} onClick={()=>navigate('/register')}>Register</button></div>
    </small>
  )
}

function Login() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [captchaVerify, setCaptchaVerify] = useState(false);

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  const handleLogin = async () => {
    setLoading(true);
    await createUserToken(formData);
    setLoading(false);
  };

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      localStorage.setItem("profile_type", data?.userprofile_fetch_item?.profile_type);
    }
  }, [data?.userprofile_fetch_item]);

  if (localStorage.getItem('tk')) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(135deg, #e0e7ff 0%, #d5d9ff 20%, #c5c7ff 40%, #b4b4ff 60%, #a3a1ff 80%, #928eff 100%)', overflowY: 'auto'  }}>
      <div style={{ margin: 30, padding: '40px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff'}}>
        <LoginForm {...{formData, setFormData, captchaVerify, setCaptchaVerify, loading, handleLogin}}/>
      </div>
    </div>
  );
}

export default Login;
