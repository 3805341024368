import React, { useState } from 'react';
import { PDFDocument, rgb, degrees } from 'pdf-lib';
import { saveAs } from 'file-saver';

const AddWatermark = () => {
  const [file, setFile] = useState(null);
  const [watermarkText, setWatermarkText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleWatermarkChange = (event) => {
    setWatermarkText(event.target.value);
  };

  const addWatermark = async () => {
    if (!file) {
      alert('Please select a PDF file.');
      return;
    }

    if (!watermarkText) {
      alert('Please enter the watermark text.');
      return;
    }

    setLoading(true);

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const typedArray = new Uint8Array(fileReader.result);
      const pdfDoc = await PDFDocument.load(typedArray);

      const pages = pdfDoc.getPages();
      pages.forEach((page) => {
        const { width, height } = page.getSize();
        page.drawText(watermarkText, {
          x: width / 2 - 50,
          y: height / 2,
          size: 50,
          opacity: 0.5,
          rotate: degrees(45),
          color: rgb(0.75, 0.75, 0.75),
        });
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, 'watermarked.pdf');
      setLoading(false);
    };
    fileReader.readAsArrayBuffer(file);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    margin: '10px',
    fontSize: '16px',
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#28a745',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Add Watermark to PDF</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} style={inputStyle} />
      <input
        type="text"
        placeholder="Watermark Text"
        value={watermarkText}
        onChange={handleWatermarkChange}
        style={inputStyle}
      />
      <button onClick={addWatermark} style={buttonStyle} disabled={loading}>
        Add Watermark
      </button>
      {loading && <div style={loaderStyle}>Loading...</div>}
    </div>
  );
};

export default AddWatermark;
