export const categories = {
  Welcome: ["WelcomeEmail", "AccountVerification", "GettingStarted", "AccountSetup"],
  PasswordReset: ["PasswordResetLink", "PasswordResetInstructions", "PasswordResetConfirmation"],
  OrderConfirmation: ["OrderSummary", "PaymentConfirmation"],
  ShippingNotification: ["OrderShipped", "TrackingInformation"],
  DeliveryConfirmation: ["OrderDelivered", "DeliveryFeedback"],
  PromotionalEmail: ["SpecialOffers", "DiscountCodes", "SeasonalPromotions"],
  Newsletter: ["MonthlyNewsletter", "ProductUpdates", "CompanyNews"],
  AccountActivity: ["NewDeviceLogin", "AccountDetailsUpdated", "SecurityAlerts"],
  EventInvitations: ["WebinarInvitations", "WorkshopInvitations", "EventReminders"],
  FeedbackRequest: ["CustomerSatisfactionSurvey", "ProductReviewRequest", "ServiceFeedbackRequest"],
  SubscriptionRenewal: ["SubscriptionRenewalReminder", "SubscriptionExpiredNotice"],
  AbandonedCart: ["CompletePurchaseReminder", "CartRecoveryDiscount"],
  ReferralProgram: ["InviteFriends", "RewardsReferralProgramUpdates"],
  CustomerSupport: ["SupportTicketReceived", "SupportTicketResolved"],
  Billing: ["Invoice", "PaymentReceipt", "BillingIssuesNotification"],
  UserOnboarding: ["OnboardingSteps", "PlatformFeaturesIntroduction"],
  MilestoneCelebrations: ["AccountAnniversary", "PurchaseMilestones"],
  LegalUpdates: ["TermsOfServiceUpdate", "PrivacyPolicyUpdate"],
  UnsubscribeConfirmation: ["SubscriptionCancellation", "ReSubscribeOptions"],
  ThankYouEmails: ["ThankYouForPurchase", "ThankYouForFeedback"]
};
