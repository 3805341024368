import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile, configureAccountUsermessagedispatch } from "services/access/account";
import { configurePluginEmailservice } from "services/access/plugin";
import { utils } from "services/api-data/src/App";
import { themeColors, getEmailStyles } from 'data/theme';
import { initialTemplateData } from 'data/initialTemplateData';
import ReactDOMServer from 'react-dom/server';
import SubcategoryComponent from 'components/layouts/Welcome/AccountVerification'
import Modal from 'components/Modal'
import { toast } from "react-toastify";

const Profile = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(utils, dispatch, state);
  const accessUsersessagedispatch = configureAccountUsermessagedispatch(utils, dispatch, state);
  const accessPluginService = configurePluginEmailservice(utils, dispatch, state);
  const [informationModelOpen, setInformationModelOpen] = useState(false)

  const [customEmailRequestContactDetail, setCustomEmailRequestContactDetail] = useState({})

  const [profileData, setProfileData] = useState({})
  const [emailServiceData, setEmailServiceData] = useState({})
  const [sendVerification, setSendVerification] = useState(true)
  const [changePassModelOpen, setChangePassModelOpen] = useState(false)
  const [savingProfileChanges, setSavingProfileChanges] = useState(false)

  const [messageTriggerLoading, setMessageTriggerLoading] = useState()
  const [isVerificationSent, setIsVerificationSent] = useState(false)
  const [isPasswordResetSent, setIsPasswordResetSent] = useState(false)

  const [savingEmailChanges, setSavingEmailChanges] = useState(false)
  const [foundUserInteraction, setFoundUserInteraction] = useState(false)
  const user_id = localStorage.getItem("user_id");
  const timeoutRefProfile = useRef(null);
  const timeoutRefEmail = useRef(null);

  const saveProfileData = () => {
    setSavingProfileChanges(true)
    setFoundUserInteraction(false)
    action.updateUserprofileItem(user_id, profileData)
    setTimeout(() => setSavingProfileChanges(false), 2000)
  }
  const saveEmailData = () => {
    setSavingEmailChanges(true)
    setFoundUserInteraction(false)
    accessPluginService.action.updateEmailserviceItem(user_id, emailServiceData)
    setTimeout(() => setSavingEmailChanges(false), 2000)
  }

  useEffect(() => {
    if (foundUserInteraction) {
      if (timeoutRefProfile.current) clearTimeout(timeoutRefProfile.current)
      timeoutRefProfile.current = setTimeout(() => saveProfileData(), 1000);
      return () => clearTimeout(timeoutRefProfile.current);
    }
  }, [profileData]);

  useEffect(() => {
    if (foundUserInteraction) {
      if (timeoutRefEmail.current) clearTimeout(timeoutRefEmail.current)
      timeoutRefEmail.current = setTimeout(() => saveEmailData(), 1000);
      return () => clearTimeout(timeoutRefEmail.current);
    }
  }, [emailServiceData]);

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
      accessPluginService.action.fetchEmailserviceItem(user_id);
    }
  }, [user_id]);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      const userProfile = data?.userprofile_fetch_item
      setProfileData(userProfile)
    }
  }, [data?.userprofile_fetch_item]);

  useEffect(() => {
    const emailService = accessPluginService?.data?.emailservice_fetch_item
    if (emailService) {
      const { default_receiving_user_name, sender_company_name, default_sender_email_address, email_subject_prefix} = emailService
      setEmailServiceData({ default_receiving_user_name, sender_company_name, default_sender_email_address, email_subject_prefix })
    }
  }, [accessPluginService?.data?.emailservice_fetch_item]);

  const updateProfileData = (field, value) => {
    setFoundUserInteraction(true)
    setProfileData({...profileData, [field]: value})
  }

  const updateEmailServiceData = (field, value) => {
    setFoundUserInteraction(true)
    if (field ==='sender_company_name') {
      setEmailServiceData({...emailServiceData, ['email_subject_prefix']: `[${value}] `, [field]: value})
    } else {
      setEmailServiceData({...emailServiceData, [field]: value})
    }
  }

  const getHTML = (template) => {
    return ReactDOMServer.renderToStaticMarkup(
      <SubcategoryComponent
        emailStyles={getEmailStyles('default')}
        values={{...emailServiceData, default_receiving_user_name: 'ACCOUNT_USER_NAME'}}
        defaultData={template}
      />
    );
  }

  useEffect(()=>{
    if (accessUsersessagedispatch?.data?.usermessagedispatch_create_item?.id) {
      if (messageTriggerLoading?.reason === 'ACCOUNT_VERIFICATION') {
        setIsVerificationSent(true)
      } else if (messageTriggerLoading?.reason === 'CHANGE_PASSWORD') {
        setIsPasswordResetSent(true)
      } else if (messageTriggerLoading?.reason === 'REQUEST_FOR_SENDERS_CUSTOM_EMAIL') {
        setInformationModelOpen(false)
        toast.success("Request Sent!");
      }
      setMessageTriggerLoading()
    }
  }, [accessUsersessagedispatch?.data?.usermessagedispatch_create_item?.id])

  const postRequest = (reason, medium) => {
    if (medium === 'EMAIL') {
      if (reason === 'ACCOUNT_VERIFICATION') {
        const template = initialTemplateData['Welcome']['AccountVerification']
        accessUsersessagedispatch.action.createUsermessagedispatchItem({ reason, medium, value: getHTML(template) })
      } else if (reason === 'CHANGE_PASSWORD') {
        const template = initialTemplateData['PasswordReset']['PasswordResetLink']
        accessUsersessagedispatch.action.createUsermessagedispatchItem({ reason, medium, value: getHTML(template) })
      }
    }
    if (medium === 'EMAIL_TO_SELF') {
      if (reason === 'REQUEST_FOR_SENDERS_CUSTOM_EMAIL') {
       const html_template = `
         <div>
           <p>Hi Corpola Tech,</p>
           <p>The following user wants the custom email service:</p>
           <p><b>Name: ${profileData?.first_name} ${profileData?.last_name}</b></p>
           <p><b>Email Address: ${customEmailRequestContactDetail.email || profileData?.email}</b></p>
           <p><b>Contact Number: ${customEmailRequestContactDetail.phone}</b></p>
           <p><b>Domain: ${customEmailRequestContactDetail.domain}</b></p>
         </div>
       `
       accessUsersessagedispatch.action.createUsermessagedispatchItem({ reason, medium, value: html_template })
     }
    }
  }
  return (
    <div style={styles.container}>
      <Modal {...{isOpen: informationModelOpen, setIsOpen: setInformationModelOpen}}>
        <h2>Use Custom Email</h2>
        <p>Hi {profileData?.first_name},</p>
        <p>Setting your own custom email for sending message to your users need some manual configuration.</p>
        <p>Please provide us your contact detail and we will get back to you.</p>
        <p>Email Address: <input type="text" onChange={e=>setCustomEmailRequestContactDetail({...customEmailRequestContactDetail, email: e.target.value})} value={customEmailRequestContactDetail.email || profileData.email}/></p>
        <p>Contact Number: <input type="text" onChange={e=>setCustomEmailRequestContactDetail({...customEmailRequestContactDetail, phone: e.target.value})} value={customEmailRequestContactDetail.phone}/></p>
        <p>Your Domain Name: <input type="text" onChange={e=>setCustomEmailRequestContactDetail({...customEmailRequestContactDetail, domain: e.target.value})} value={customEmailRequestContactDetail.domain}/></p>
        {messageTriggerLoading?.reason === 'REQUEST_FOR_SENDERS_CUSTOM_EMAIL' ? <p style={{width: 'fit-content', border: '1px solid green', backgroundColor: '#00FF0022', padding: 10}}>Loading...</p>
        :
        <button onClick={()=>{
          const reason = 'REQUEST_FOR_SENDERS_CUSTOM_EMAIL'
          const medium = 'EMAIL_TO_SELF'
          setMessageTriggerLoading({reason, medium});
          postRequest(reason, medium)
        }} style={{border: 'none', padding: 10, cursor: 'pointer', backgroundColor: '#007BFF', color: 'white'}}>Apply for custom email</button>}
      </Modal>
      <div style={{ padding: 40 }}>
        <h2 style={styles.header}>
          <span>Profile</span>
          {savingProfileChanges && <span style={{marginLeft: 10, fontSize: 9, padding: 3, fontWeight: 600, borderRadius: 5, backgroundColor: '#007BFF', color: 'white'}}>Changes saved!</span>}
        </h2>
        {profileData.account_blocked && <div style={{border: '1px solid #c3092b', width: 'fit-content', padding: 10, backgroundColor: '#c3092b22', marginBottom: 20}}>
          <div><b>Your account is blocked by the system</b></div>
          <div style={{marginTop: 5, paddingTop: 5, borderTop: '1px solid #c3092b'}}>Some features may not work as expected.</div>
          <div style={{marginTop: 5}}><i>Reason: {profileData.account_blocked_reason}</i></div>
          <div style={{marginTop: 5}}>Contact the admin to remove the block.</div>
        </div>}
        <div style={styles.infoContainer}>
          <div style={styles.infoItem}>
            <label style={styles.label}>First Name:</label>
            <input
              style={styles.input}
              type="text"
              value={profileData.first_name}
              onChange={(e) => updateProfileData('first_name',e.target.value)}
            />
          </div>
          <div style={styles.infoItem}>
            <label style={styles.label}>Last Name:</label>
            <input
              style={styles.input}
              type="text"
              value={profileData.last_name}
              onChange={(e) => updateProfileData('last_name',e.target.value)}
            />
          </div>
          <div style={styles.infoItem}>
            <label style={styles.label}>Email Address:</label>
            <input
              style={{...styles.input, ...styles.notAllowed}}
              type="text"
              value={profileData.email}
              onChange={(e) => updateProfileData('email',e.target.value)}
              disabled
            />
          </div>
          {((!(messageTriggerLoading?.reason === 'ACCOUNT_VERIFICATION') && profileData.is_email_verified == 'EMAIL_SENT') || isVerificationSent) && <div style={styles.infoItem}>
            <small style={{color: '#7c4f00'}}><b>Account verification is pending. Please check your email inbox for the verification email.</b></small>
          </div>}
          {sendVerification && !(messageTriggerLoading?.reason === 'ACCOUNT_VERIFICATION') && profileData.is_email_verified == 'EMAIL_SENT' && (
            <button
              style={{...styles.link, marginRight: 10}}
              onClick={()=>{
                const reason = 'ACCOUNT_VERIFICATION'
                const medium = 'EMAIL'
                setMessageTriggerLoading({reason, medium});
                setSendVerification(false);
                postRequest(reason, medium)
              }}>
                Send me verification link again
              </button>
          )}
          {(!isVerificationSent && (!(messageTriggerLoading?.reason === 'ACCOUNT_VERIFICATION') && profileData.is_email_verified == 'NO')) && <div style={styles.infoItem}>
            <small style={{color: '#c3092b'}}><b>Account is not verified!</b></small>
          </div>}
          {(!isVerificationSent && (sendVerification && !(messageTriggerLoading?.reason === 'ACCOUNT_VERIFICATION') && profileData.is_email_verified == 'NO')) && (
            <button
              style={{...styles.link, marginRight: 10}}
              onClick={()=>{
                const reason = 'ACCOUNT_VERIFICATION'
                const medium = 'EMAIL'
                setMessageTriggerLoading({reason, medium});
                postRequest(reason, medium)
              }}>
                Verify through email
              </button>
          )}
          {messageTriggerLoading?.reason && <>Loading...</>}
          {isPasswordResetSent &&
            <div style={styles.infoItem}>
              <small style={{color: '#7c4f00'}}><b>A password reset link has been sent to your email.</b></small>
            </div>
          }
          {((!(messageTriggerLoading?.reason)) && (!isPasswordResetSent && profileData.is_email_verified == 'YES')) &&
            <button
              style={{...styles.link, marginRight: 10}}
              onClick={()=>{
                setChangePassModelOpen(true)
              }}
            >
              Change Password
            </button>}
          <Modal {...{isOpen: changePassModelOpen, setIsOpen: setChangePassModelOpen}}>
            <h2>Change Password</h2>
            <p>Are you sure you want to change password?</p>
            <p>On your request, we will send a reset link at your email.</p>
            <button onClick={()=>{
              const reason = 'CHANGE_PASSWORD'
              const medium = 'EMAIL'
              setMessageTriggerLoading({reason, medium});
              postRequest(reason, medium)
              setChangePassModelOpen(false)
            }} style={{border: 'none', padding: 10, cursor: 'pointer', backgroundColor: '#007BFF', color: 'white'}}>Send me reset link</button>
          </Modal>
          {profileData.is_email_verified !== 'YES' && <button style={{border: 'none', marginRight: 10, color: '#999'}}><u>Change Password</u> (Only email verified account can change password)</button>}
        </div>

        <h2 style={styles.header}>
          <span>Email Setup</span>
          {savingEmailChanges && <span style={{marginLeft: 10, fontSize: 9, padding: 3, fontWeight: 600, borderRadius: 5, backgroundColor: '#007BFF', color: 'white'}}>Changes saved!</span>}
        </h2>

        <div style={styles.inputDemoContainer}>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Default email receiving user name:</label>
            <input
              style={styles.input}
              type="text"
              value={emailServiceData.default_receiving_user_name}
              onChange={(e) => updateEmailServiceData('default_receiving_user_name',e.target.value)}
            />
          </div>
          <div style={{flex:1}}>
            <small><b>USED AS</b></small>
            <div style={styles.emailPreview}>
              Dear <b>{emailServiceData.default_receiving_user_name}</b>,<br /><br />EMAIL_BODY
            </div>
          </div>
        </div>

        <div style={styles.inputDemoContainer}>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Sender's Company Name:</label>
            <input
              style={styles.input}
              type="text"
              value={emailServiceData.sender_company_name}
              onChange={(e) => {
                updateEmailServiceData('sender_company_name',e.target.value)
              }}
            />
          </div>
          <div style={{flex:1}}>
            <small><b>USED AS</b></small>
            <div style={styles.emailSignature}>
              <div>Thank you for being a valued customer!</div>
              <div>© {new Date().getFullYear()} <b>{emailServiceData.sender_company_name}</b> All rights reserved.</div>
            </div>
          </div>
        </div>

        <div style={styles.inputDemoContainer}>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Default Sender's Email Address:</label>
            <input
              style={{...styles.input, ...styles.notAllowed}}
              type="email"
              disabled
              value={emailServiceData.default_sender_email_address}
              onChange={(e) => updateEmailServiceData('default_sender_email_address', e.target.value)}
            />
            <div><small><button style={styles.link} onClick={()=>setInformationModelOpen(true)}>Use custom email</button></small></div>
          </div>
          <div>
          </div>
        </div>

        <div style={styles.inputDemoContainer}>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Email Subject Prefix:</label>
            <input
              style={styles.input}
              type="text"
              value={emailServiceData.email_subject_prefix}
              onChange={(e) => updateEmailServiceData('email_subject_prefix',e.target.value)}
            />
          </div>
          <div style={{flex:1}}>
            <small><b>USED AS</b></small>
              <div style={styles.emailSubject}>
              <div style={{display: 'flex'}}><div style={{marginTop: 7}}>Subject</div> <div style={styles.subjectPrefix}><b>{emailServiceData.email_subject_prefix}</b> EMAIL_SUBJECT</div></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

const styles = {
  container: {
    fontSize: 13,
    margin: 'auto',
    height: '100vh',
    overflowY: 'auto'
  },
  header: {
    color: '#333',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  infoContainer: {
    marginBottom: '20px',
  },
  infoItem: {
    marginBottom: '10px',
  },
  button: {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    margin: '5px 0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  inputDemoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  inputContainer: {
    flex: 1,
    marginRight: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '90%',
    maxWidth: 480,
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  emailPreview: {
    border: '1px solid #ccc',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  emailSignature: {
    border: '1px solid #ccc',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '10px',
    fontSize: '12px',
    color: '#555',
    textAlign: 'center',
    flex: 1,
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
    marginLeft: '10px',
    textDecoration: 'underline',
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
    cursor: 'pointer',
    padding: 10
  },
  emailSubject: {
    border: '1px solid #ccc',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '10px',
    flex: 1,
  },
  subjectPrefix: {
    border: '1px solid #ccc',
    padding: '5px',
    marginLeft: '10px',
    flex: 1
  },
  notAllowed: {
    cursor: 'not-allowed'
  },
};

export default Profile;
