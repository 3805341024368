// "messages", "buttons", "data" and "total"
export const initialTemplateData = {
  "Welcome": {
    "WelcomeEmail": {
      "messages": [
        "We are thrilled to have you on board. We strive to provide the best quality exceptional customer service.",
        "Feel free to explore our wide range of products and discover the best deals tailored just for you."
      ],
      "buttons": [
        {
          "label": "Start Shopping",
          "link": "SITE_LINK"
        }
      ]
    },
    "AccountVerification": {
      "messages": [
        "Thank you for registering with us. Please click the button below to verify your email address and activate your account.",
        "If you did not create an account, please ignore this email."
      ],
      "buttons": [
        {
          "label": "Verify Account",
          "link": "HTTP_LINK_FOR_ACCOUNT_VERIFICATION"
        }
      ]
    },
    "GettingStarted": {
      "messages": [
        "We are excited to welcome you to the community. Getting started is easy!",
        "Follow the steps below to begin your journey with us:",
        "If you have any questions or need assistance, our customer service team is here to help."
      ],
      "buttons": [
        {
          "label": "Visit the site",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Explore our extensive catalog of products.",
        "Add your favorite items to the cart.",
        "Checkout with our secure payment options.",
        "Enjoy your purchase delivered straight to your doorstep."
      ]
    },
    "AccountSetup": {
      "messages": [
        "We are delighted to have you with us. To get started, please follow the steps below to set up your account.",
        "If you need any help during the setup process, feel free to reach out to our support team."
      ],
      "buttons": [
        {
          "label": "Set Up Your Account",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Click the link below to verify your email address.",
        "Create a strong password for your account.",
        "Fill in your profile details to personalize your experience.",
        "Explore our wide range of products and start shopping!"
      ]
    }
  },
  "PasswordReset": {
    "PasswordResetLink": {
      "messages": [
        "We received a request to reset your password for your account",
        "If you did not request a password reset, please ignore this email. Otherwise, you can reset your password using the link below:",
        "If you encounter any issues or have any questions, please do not hesitate to contact our support team."
      ],
      "buttons": [
        {
          "label": "Reset Your Password",
          "link": "HTTP_LINK_FOR_CHANGING_PASSWORD"
        }
      ],
    },
    "PasswordResetInstructions": {
      "messages": [
        "We received a request to reset your password for your account. Please follow the steps below to reset your password:",
        "If you did not request a password reset, please ignore this email or contact our support team for assistance."
      ],
      "buttons": [
        {
          "label": "Reset Your Password",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Click the link below to open the password reset page.",
        "Enter your new password.",
        "Confirm your new password.",
        "Submit the form to complete the process."
      ]
    },
    "PasswordResetConfirmation": {
      "messages": [
        "Your password has been successfully reset. You can now use your new password to log in to your account.",
        "If you did not request this change, please contact our support team immediately."
      ],
      "buttons": [
        {
          "label": "Log In",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "OrderConfirmation": {
    "OrderSummary": {
      "messages": [
        "Thank you for your order! Here is a summary of your purchase:",
        "If you have any questions about your order, please contact our support team."
      ],
      "buttons": [
        {
          "label": "Visit the site",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        {
          "item": "Product A",
          "quantity": 2,
          "price": "€10.00",
          "total": "€20.00"
        },
        {
          "item": "Product B",
          "quantity": 1,
          "price": "€15.00",
          "total": "€15.00"
        }
      ],
      "total": "€35.00"
    },
    "PaymentConfirmation": {
      "messages": [
        "We have successfully received your payment for your recent order.",
        "Your order is now confirmed and will be processed shortly.",
        "For more details about your order or to track its progress, please visit our website:"
      ],
      "buttons": [
        {
          "label": "Track Your Order",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "ShippingNotification": {
    "OrderShipped": {
      "messages": [
        "We are excited to let you know that your order has been shipped. Here are the details of your shipment:",
        "If you have any questions about your shipment, please contact our support team."
      ],
      "buttons": [
        {
          "label": "Visit the site",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        {
          "item": "Product A",
          "quantity": 2,
          "trackingNumber": "123456789",
          "carrier": "DHL"
        },
        {
          "item": "Product B",
          "quantity": 1,
          "trackingNumber": "987654321",
          "carrier": "FedEx"
        }
      ]
    },
    "TrackingInformation": {
      "messages": [
        "We are pleased to provide you with the tracking information for your recent shipment:",
        "To track your shipment, please click on the button below:"
      ],
      "buttons": [
        {
          "label": "Track Your Shipment",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        {
          "item": "Product A",
          "quantity": 2,
          "trackingNumber": "123456789",
          "carrier": "DHL"
        },
        {
          "item": "Product B",
          "quantity": 1,
          "trackingNumber": "987654321",
          "carrier": "FedEx"
        }
      ]
    }
  },
  "DeliveryConfirmation": {
    "OrderDelivered": {
      "messages": [
        "We are happy to inform you that your order has been successfully delivered to you.",
        "If you have any questions or need further assistance, please do not hesitate to contact our support team."
      ],
      "buttons": [
        {
          "label": "Visit Us",
          "link": "SITE_LINK"
        }
      ]
    },
    "DeliveryFeedback": {
      "messages": [
        "We hope your recent delivery arrived safely and on time.",
        "Your feedback is valuable to us! Please take a moment to let us know about your delivery experience.",
        "If you have any questions or need assistance, please contact our support team."
      ],
      "buttons": [
        {
          "label": "Provide Feedback",
          "link": "SITE_LINK"
        },
        {
          "label": "Visit the site",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "PromotionalEmail": {
    "SpecialOffers": {
      "messages": [
        "Discover our latest special offers and promotions!",
        "Don't miss out on these exclusive deals:",
        "Visit our website to explore more:"
      ],
      "buttons": [
        {
          "label": "Explore Special Offers",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Special Discount on Product A",
        "Buy One Get One Free on Product B"
      ]
    },
    "DiscountCodes": {
      "messages": [
        "Here are your exclusive discount codes:",
        "Use these codes at checkout to enjoy your discounts:"
      ],
      "buttons": [
        {
          "label": "Shop Now",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Code A: 10% off your next purchase",
        "Code B: Free shipping on orders over €50"
      ]
    },
    "SeasonalPromotions": {
      "messages": [
        "Explore our latest seasonal promotions at our store!",
        "Take advantage of these limited-time offers:",
        "Visit our website to discover more:"
      ],
      "buttons": [
        {
          "label": "Discover Promotions",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Spring Sale: Up to 20% off selected items",
        "Summer Clearance: Buy 2, Get 1 Free"
      ]
    }
  },
  "Newsletter": {
    "MonthlyNewsletter": {
      "messages": [
        "Here's what's happening at our company this month:",
        "Stay informed and don't miss out on exciting updates!"
      ],
      "buttons": [
        {
          "label": "Read Newsletter",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "New product launches",
        "Exclusive offers and discounts",
        "Upcoming events and promotions"
      ]
    },
    "ProductUpdates": {
      "messages": [
        "Stay informed with the latest product updates from our company:",
        "Explore our latest offerings and enhancements:"
      ],
      "buttons": [
        {
          "label": "Discover Products",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "New arrivals and releases",
        "Feature updates and improvements",
        "Tips and recommendations"
      ]
    },
    "CompanyNews": {
      "messages": [
        "Stay updated with the latest news and announcements from our company:",
        "Explore our company updates and learn more:"
      ],
      "buttons": [
        {
          "label": "Read Company News",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Company milestones and achievements",
        "Industry insights and trends",
        "Upcoming events and initiatives"
      ]
    }
  },
  "AccountActivity": {
    "NewDeviceLogin": {
      "messages": [
        "We noticed a new login to your account on a new device.",
        "If this was you, you can disregard this message. If you didn't initiate this login, please secure your account immediately.",
        "For security reasons, we recommend:"
      ],
      "buttons": [
        {
          "label": "Visit the site",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Reviewing your account activity regularly",
        "Changing your password if you suspect unauthorized access"
      ]
    },
    "AccountDetailsUpdated": {
      "messages": [
        "We're writing to inform you that your account details have been updated successfully.",
        "If you made these changes, you can disregard this message. If you didn't initiate these changes, please secure your account immediately.",
        "If you have any questions or concerns, please contact our support team."
      ],
      "buttons": [
        {
          "label": "Visit",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Reviewing your updated account details",
        "Changing your password if you suspect unauthorized changes"
      ]
    },
    "SecurityAlerts": {
      "messages": [
        "We've detected unusual activity on your account that may indicate unauthorized access.",
        "If you recognize this activity, you can disregard this message. If you don't recognize it, please secure your account immediately.",
        "For your safety, we recommend:",
        "If you have any concerns or need assistance, please contact our support team."
      ],
      "buttons": [
        {
          "label": "Visit the site",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Reviewing recent account activity",
        "Changing your password immediately if you suspect unauthorized access"
      ]
    }
  },
  "EventInvitations": {
    "WebinarInvitations": {
      "messages": [
        "You are invited to join our upcoming webinar:",
        "Don't miss this opportunity to learn from industry experts and discover valuable insights!",
        "Secure your spot now:"
      ],
      "buttons": [
        {
          "label": "Register Now",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Topic: Enhancing Your Business Strategy",
        "Date: [Webinar Date]",
        "Time: [Webinar Time]"
      ]
    },
    "WorkshopInvitations": {
      "messages": [
        "You are invited to join our upcoming workshop:",
        "Experience hands-on learning and expert guidance!"
      ],
      "buttons": [
        {
          "label": "Register Now",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Topic: Change you mindset",
        "Date: [Workshop Date]",
        "Time: [Workshop Time]"
      ]
    },
    "EventReminders": {
      "messages": [
        "Just a friendly reminder about our upcoming event:",
        "Join us for an engaging session and insightful discussions!"
      ],
      "buttons": [
        {
          "label": "Register Now",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Event: [Event Name]",
        "Date: [Event Date]",
        "Time: [Event Time]"
      ]
    }
  },
  "FeedbackRequest": {
    "CustomerSatisfactionSurvey": {
      "messages": [
        "We would love to hear about your recent experience!",
        "Your feedback helps us improve our products and services to better serve you.",
        "Take a moment to complete our Customer Satisfaction Survey:"
      ],
      "buttons": [
        {
          "label": "Take Survey",
          "link": "SITE_LINK"
        }
      ]
    },
    "ProductReviewRequest": {
      "messages": [
        "We hope you are enjoying your recent purchase!",
        "Your feedback matters to us. Please take a moment to review the product:",
        "Share your thoughts and help other customers make informed decisions.",
        "Review the product here:"
      ],
      "buttons": [
        {
          "label": "Write a Review",
          "link": "SITE_LINK"
        }
      ]
    },
    "ServiceFeedbackRequest": {
      "messages": [
        "We hope your recent experience was satisfactory.",
        "Your feedback helps us improve our services. Please take a moment to share your thoughts:",
        "Provide your feedback here:"
      ],
      "buttons": [
        {
          "label": "Give Feedback",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "SubscriptionRenewal": {
    "SubscriptionRenewalReminder": {
      "messages": [
        "We want to remind you that your subscription is up for renewal soon.",
        "To continue enjoying our services without interruption, please renew your subscription.",
        "Renew your subscription now:"
      ],
      "buttons": [
        {
          "label": "Renew Now",
          "link": "SITE_LINK"
        }
      ]
    },
    "SubscriptionExpiredNotice": {
      "messages": [
        "We regret to inform you that your subscription to our service has expired.",
        "To continue enjoying our services, please renew your subscription.",
        "Renew your subscription now:"
      ],
      "buttons": [
        {
          "label": "Renew Now",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "AbandonedCart": {
    "CompletePurchaseReminder": {
      "messages": [
        "We noticed that you have items in your cart at our store.",
        "Complete your purchase now and enjoy your favorite products.",
        "Complete your purchase here:"
      ],
      "buttons": [
        {
          "label": "Complete Purchase",
          "link": "SITE_LINK"
        }
      ]
    },
    "CartRecoveryDiscount": {
      "messages": [
        "It looks like you left items in your cart.",
        "To encourage you to complete your purchase, here's a special discount:",
        "Complete your purchase now:"
      ],
      "buttons": [
        {
          "label": "Complete Purchase",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Use code SAVE10 to get 10% off on your next purchase"
      ]
    }
  },
  "ReferralProgram": {
    "InviteFriends": {
      "messages": [
        "Share your love with your friends and earn rewards!",
        "Invite your friends to join and both of you will receive exclusive benefits:",
        "Invite your friends now:"
      ],
      "buttons": [
        {
          "label": "Invite Friends",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Refer friends using your unique referral link.",
        "Your friends get a special welcome offer.",
        "You earn rewards for successful referrals."
      ]
    },
    "RewardsReferralProgramUpdates": {
      "messages": [
        "We are excited to share updates on our Rewards Referral Program!",
        "Here's what's new:",
        "Start referring your friends now and earn rewards:"
      ],
      "buttons": [
        {
          "label": "Learn More",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Enhanced referral bonuses for you and your friends.",
        "Improved tracking and rewards system.",
        "More ways to earn rewards by referring friends."
      ]
    }
  },
  "CustomerSupport": {
    "SupportTicketReceived": {
      "messages": [
        "We have received your support ticket.",
        "Our team is reviewing your request and will get back to you shortly.",
        "If you have any urgent inquiries, please visit our support page:"
      ],
      "buttons": [
        {
          "label": "Visit Support",
          "link": "SITE_LINK"
        }
      ]
    },
    "SupportTicketResolved": {
      "messages": [
        "We are pleased to inform you that your support ticket has been resolved.",
        "If you have any further questions or need additional assistance, please don't hesitate to contact us."
      ],
      "buttons": [
        {
          "label": "Visit Support",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "Billing": {
    "Invoice": {
      "messages": [
        "Thank you for your recent purchase.",
        "Here are the details of your invoice:",
        "Please complete your payment to avoid any disruptions to your account.",
        "For any questions or concerns, please visit our billing support page:"
      ],
      "buttons": [
        {
          "label": "Visit Billing Support",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        {
          "description": "Product A",
          "quantity": 2,
          "unitPrice": "$20.00",
          "total": "$40.00"
        },
        {
          "description": "Product B",
          "quantity": 1,
          "unitPrice": "$30.00",
          "total": "$30.00"
        }
      ],
      "total": "$70.00"
    },
    "PaymentReceipt": {
      "messages": [
        "Thank you for your recent payment.",
        "Here are the details of your payment receipt:",
        "If you have any questions regarding this payment, please contact our billing department:"
      ],
      "buttons": [
        {
          "label": "Visit Billing Support",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        {
          "date": "July 8, 2024",
          "amount": "$100.00",
          "method": "Credit Card"
        }
      ]
    },
    "BillingIssuesNotification": {
      "messages": [
        "We are writing to inform you about some issues regarding your recent billing.",
        "Please review the details below:",
        "For assistance in resolving this matter, please visit our billing support page:"
      ],
      "buttons": [
        {
          "label": "Visit Billing Support",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "An issue occurred with processing your recent payment.",
        "Action required: Please update your payment information or contact our billing department."
      ]
    }
  },
  "UserOnboarding": {
    "OnboardingSteps": {
      "messages": [
        "Welcome aboard! We're excited to have you join our community.",
        "Follow these steps to get started on your journey with us:",
        "For more information and to start shopping, visit our website:"
      ],
      "buttons": [
        {
          "label": "Visit Our Website",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Create your account on our website.",
        "Browse our wide selection of products.",
        "Place your first order and enjoy our quality products!"
      ]
    },
    "PlatformFeaturesIntroduction": {
      "messages": [
        "Welcome aboard! We're thrilled to have you join our platform.",
        "Here's an introduction to our platform features:",
        "Start exploring and discovering more about our platform:"
      ],
      "buttons": [
        {
          "label": "Explore",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Explore our extensive catalog of products.",
        "Effortlessly manage your orders and subscriptions.",
        "Enjoy personalized recommendations based on your preferences."
      ]
    }
  },
  "MilestoneCelebrations": {
    "AccountAnniversary": {
      "messages": [
        "Happy account anniversary! We are delighted to celebrate this milestone.",
        "Thank you for being a valued member of our community.",
        "Continue exploring our latest products and services:"
      ],
      "buttons": [
        {
          "label": "Explore",
          "link": "SITE_LINK"
        }
      ]
    },
    "PurchaseMilestones": {
      "messages": [
        "Congratulations! We're excited to celebrate your purchase milestones.",
        "Thank you for choosing our products and being a part of our journey.",
        "Explore more of our offerings:"
      ],
      "buttons": [
        {
          "label": "Explore",
          "link": "SITE_LINK"
        }
      ]
    }
  },
  "LegalUpdates": {
    "TermsOfServiceUpdate": {
      "messages": [
        "We are writing to inform you about recent updates to our Terms of Service.",
        "Please review the updated terms to understand how they affect you:",
        "For more details, visit our website:"
      ],
      "buttons": [
        {
          "label": "Review Terms of Service",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Changes to our privacy policy.",
        "New terms regarding user responsibilities.",
        "Information about your rights and obligations."
      ]
    },
    "PrivacyPolicyUpdate": {
      "messages": [
        "We are writing to inform you about recent updates to our Privacy Policy.",
        "It is important that you review these changes to understand how they affect your privacy rights:",
        "For more details, please visit our website:"
      ],
      "buttons": [
        {
          "label": "Review Privacy Policy",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Updated information on data collection and usage.",
        "New privacy protections and your rights.",
        "Details on how we handle your personal information."
      ]
    }
  },
  "UnsubscribeConfirmation": {
    "SubscriptionCancellation": {
      "messages": [
        "We have received your request to cancel your subscription.",
        "Your subscription has been successfully canceled.",
        "If you have any questions or would like to reactivate your subscription, please contact us.",
        "Visit our website for more information:"
      ],
      "buttons": [
        {
          "label": "Explore",
          "link": "SITE_LINK"
        }
      ]
    },
    "ReSubscribeOptions": {
      "messages": [
        "We noticed you've unsubscribed from emails.",
        "If you'd like to resubscribe or adjust your email preferences, you can do so:",
        "Visit our website to manage your subscription:"
      ],
      "buttons": [
        {
          "label": "Manage Preferences",
          "link": "SITE_LINK"
        }
      ],
      "data": [
        "Resubscribe to receive updates and offers.",
        "Adjust your email preferences."
      ]
    }
  },
  "ThankYouEmails": {
    "ThankYouForPurchase": {
      "messages": [
        "Thank you for your recent purchase.",
        "We appreciate your business and hope you enjoy your products.",
        "For more information about our products and services, please visit our website:"
      ],
      "buttons": [
        {
          "label": "Explore",
          "link": "SITE_LINK"
        }
      ]
    },
    "ThankYouForFeedback": {
      "messages": [
        "Thank you for providing feedback.",
        "Your input is valuable to us as we strive to improve our products and services.",
        "For more information about our offerings, please visit our website:"
      ],
      "buttons": [
        {
          "label": "Explore",
          "link": "SITE_LINK"
        }
      ]
    }
  }
}
;
