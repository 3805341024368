const Contact = ({minimal}) => {
  return(
    <footer id="contact" style={styles.footer}>
      <h2 style={styles.footerTitle}>Contact Us</h2>
      <p style={styles.footerText}>Email: info@corpolatech.com</p>
      <div style={styles.socialLinks}>
        <a href="https://www.facebook.com/corpolatech" style={styles.socialLink}>Facebook Page</a>
      </div>
    </footer>
  )
}

const styles = {
  footer: {
    padding: '50px 20px',
    textAlign: 'center',
  },
  footerTitle: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  footerText: {
    marginBottom: '10px',
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  socialLink: {
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold'
  },
};
 export default Contact
