import React, { useState, useEffect, lazy, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";
import axios from 'axios';
import LayoutSelector from './LayoutSelector';
import ReactDOMServer from 'react-dom/server';
import { initialTemplateData } from '../data/initialTemplateData';
import { categories } from '../data/categories';
import { themeColors, getEmailStyles } from '../data/theme';
import Modal from './Modal';
import DynamicForm from './DynamicForm';
import {API_BASE_URL} from 'App'
import { configurePluginEmail, configurePluginEmailgroup } from "services/access/plugin";
import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader';

const csv_data_component = [
  {
    "RECEIVING USER NAME": "User Name",
    "RECEIPIENTS": "info@trustmailservice.com",
    "EMAIL SUBJECT": "Welcome to our service",
  },
];

const parseCsvToData = (csv) => {
  const headers = csv[0];
  const parsedData = csv.slice(1).map((row, idx) => {
    const parsedRow = {
      messages: [],
      buttons: [],
      data: [],
    };

    headers.forEach((header, index) => {
      if (header.startsWith('MESSAGE')) {
        parsedRow.messages.push(row[index]);
      } else if (header.startsWith('BUTTON')) {
        const [_, buttonIndex, key] = header.split(' ');
        if (!parsedRow.buttons[buttonIndex - 1]) {
          parsedRow.buttons[buttonIndex - 1] = {};
        }
        parsedRow.buttons[buttonIndex - 1][key.toLowerCase()] = row[index];
      } else if (header.startsWith('DATA')) {
        const [_, dataIndex, key] = header.split(' ');
        if (!parsedRow.data[dataIndex - 1]) {
          if (key) {
            parsedRow.data[dataIndex - 1] = {};
          } else {
            parsedRow.data[dataIndex - 1] = '';
          }
        }
        if (key) {
          parsedRow.data[dataIndex - 1][key.toLowerCase()] = row[index];
        } else {
          parsedRow.data[dataIndex - 1] = row[index];
        }
      } else if (header === 'TOTAL') {
        parsedRow.total = row[index];
      } else {
        parsedRow[header] = row[index];
      }
    });
    return parsedRow;
  });

  return parsedData;
};

const CsvComponent = ({ uploadedData, setUploadedData, structure, csvData }) => {
  const handleForce = (data, fileInfo) => {
    setUploadedData(data);
  };

  const convertToCsv = (data, structure) => {
    const headers = [
      'RECEIVING USER NAME',
      'RECEIPIENTS',
      'EMAIL SUBJECT'
    ];
    const rows = [];

    // Collect structure headers
    structure.messages?.forEach((_, index) => {
      headers.push(`MESSAGE ${index + 1}`);
    });
    structure.buttons?.forEach((_, index) => {
      headers.push(`BUTTON ${index + 1} LABEL`);
      headers.push(`BUTTON ${index + 1} LINK`);
    });
    structure.data?.forEach((datum, index) => {
      if (typeof datum === 'string') {
        headers.push(`DATA ${index + 1}`);
      } else {
        Object.keys(datum).forEach((key) => {
          headers.push(`DATA ${index + 1} ${key.toUpperCase()}`);
        });
      }
    });
    headers.push('TOTAL');

    data.forEach(entry => {
      const row = {
        'RECEIVING USER NAME': entry['RECEIVING USER NAME'],
        'RECEIPIENTS': entry['RECEIPIENTS'],
        'EMAIL SUBJECT': entry['EMAIL SUBJECT'],
      };

      structure.messages?.forEach((message, index) => {
        row[`MESSAGE ${index + 1}`] = message;
      });

      structure.buttons?.forEach((button, index) => {
        row[`BUTTON ${index + 1} LABEL`] = button.label;
        row[`BUTTON ${index + 1} LINK`] = button.link;
      });

      structure.data?.forEach((datum, index) => {
        if (typeof datum === 'string') {
          row[`DATA ${index + 1}`] = datum;
        } else {
          Object.keys(datum).forEach((key) => {
            row[`DATA ${index + 1} ${key.toUpperCase()}`] = datum[key];
          });
        }
      });

      row['TOTAL'] = structure.total;

      if (entry['RECEIPIENTS']) {
        rows.push(row);
      }
    });

    return [headers, ...rows.map(row => headers.map(header => row[header] || ''))];
  };
  // const csv = convertToCsv(csv_data_component, structure);
  const csv = convertToCsv(parseCsvToData(csvData), structure);

  useEffect(() => {
    setUploadedData(csv)
  }, [structure])

  return (
    <div>
      <div><i>Default email content is used, if you do not want to modify and upload.</i></div>
      <CSVLink data={csv} filename={"form.csv"} className="btn btn-primary">
        1. Download & modify email content.
      </CSVLink>
      <CSVReader
        cssClass="csv-reader-input"
        label="2. Select CSV with edited data"
        onFileLoaded={handleForce}
        onError={() => console.error('Error reading CSV file')}
        inputId="csvUpload"
        inputStyle={{ color: 'red' }}
      />
    </div>
  );
};

const CsvUploadComponent = ({csvData, setCsvData}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleForce = (data, fileInfo) => {
    setCsvData(data);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thStyles = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#f2f2f2',
  };

  const tdStyles = {
    border: '1px solid #ddd',
    padding: '8px',
  };

  return (
    <div>
      <CSVReader
        cssClass="csv-reader-input"
        label="2. Select CSV with edited data"
        onFileLoaded={handleForce}
        onError={() => console.error('Error reading CSV file')}
        inputId="csvUpload"
        inputStyle={{color: 'red'}}
      />
      <button onClick={toggleModal}>View Data</button>
      <Modal isOpen={isOpen} setIsOpen={toggleModal}>
        <h3>Uploaded CSV Data</h3>
        <table style={tableStyles}>
          <thead>
            <tr>
              <th style={thStyles}>RECEIVING USER NAME</th>
              <th style={thStyles}>RECEIPIENTS</th>
              <th style={thStyles}>EMAIL SUBJECT</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => (
              <tr key={index}>
                <td style={tdStyles}>{row[0]}</td>
                <td style={tdStyles}>{row[1]}</td>
                <td style={tdStyles}>{row[2]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br/>
        <button onClick={toggleModal}>Close</button>
      </Modal>
    </div>
  );
};

const CsvDownloadComponent = () => {
  const headers = [
    { label: "RECEIVING USER NAME", key: "RECEIVING USER NAME" },
    { label: "RECEIPIENTS", key: "RECEIPIENTS" },
    { label: "EMAIL SUBJECT", key: "EMAIL SUBJECT" },
  ];

  return (
    <div>
      <CSVLink
        data={csv_data_component}
        headers={headers}
        filename={"example.csv"}
        className="btn btn-primary"
      >
        1. Download CSV
      </CSVLink>
    </div>
  );
};


const SelectContacts = ({proceed}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [listing, setListing] = useState('emails')
  const { initialize, data, action } = configurePluginEmail(utils, dispatch, state);
  const emailGroupConfiguration = configurePluginEmailgroup(utils, dispatch, state);

  const [searchEmailValue, setSearchEmailValue] = useState()
  const [searchGroupValue, setSearchGroupValue] = useState()
  const [selectedItems, setSelectedItems] = useState({type: 'emails', items: []})

  const [emails, setEmails] = useState([]);
  const [groups, setGroups] = useState([]);

  const [requestToFetch, setRequestToFetch] = useState(false);

  useEffect(()=>{
    if (listing==='emails') {
      action.fetchEmailList()
      setRequestToFetch(true)
    } else if (listing==='groups') {
      emailGroupConfiguration.action.fetchEmailgroupList()
      setRequestToFetch(true)
    }
    setSearchEmailValue()
    setSearchGroupValue()
    setSelectedItems({type: listing, items: []})
  }, [listing])

  useEffect(()=>{
    if (data?.email_fetch_list) {
      setEmails(data.email_fetch_list)
      setRequestToFetch(false)
    }
  },[data?.email_fetch_list])

  useEffect(()=>{
    if (emailGroupConfiguration?.data?.emailgroup_fetch_list) {
      setGroups(emailGroupConfiguration.data.emailgroup_fetch_list)
      setRequestToFetch(false)
    }
  },[emailGroupConfiguration?.data?.emailgroup_fetch_list])

  const filteredEmails = emails
    .filter(email => {
      const searchValue = (searchEmailValue || '').toLowerCase();
      const emailName = email.name.toLowerCase();
      const emailAddress = email.email.toLowerCase();
      return emailName.includes(searchValue) || emailAddress.includes(searchValue);
    })

  const filteredGroups = groups
    .filter(group => {
      const searchValue = (searchGroupValue || '').toLowerCase();
      const groupName = group.name.toLowerCase();
      return groupName.includes(searchValue);
    })

  const onClick = (id) => {
    let items = selectedItems.items
    if (items.indexOf(id) > -1) {
      items = items.filter(item_id=>item_id!==id)
    } else {
      items = [...items, id]
    }
    setSelectedItems({...selectedItems, items})
  }

  return(
    <div>
      <h3>Select Contacts</h3>
      <div>
        <button onClick={()=>setListing('emails')} style={{ marginRight: 5, fontSize: 11, border: 'none', backgroundColor: '#007BFF', color: 'white', borderRadius: 5, padding: 5, cursor:'pointer'}}>List all contacts</button>
        <button onClick={()=>setListing('groups')} style={{ fontSize: 11, border: 'none', backgroundColor: '#007BFF', color: 'white', borderRadius: 5, padding: 5, cursor:'pointer'}}>List all groups</button>
      </div>
      {requestToFetch && <div style={{border: '1px solid #999', backgroundColor: '#ddd', padding: 5, marginTop: 5}}>Loading...</div>}
      {listing === 'emails' && <div>
        {!requestToFetch && emails.length === 0 && <div>No email exists</div>}
        <div style={{padding: 5, marginTop: 5, border: '1px solid #999', fontSize: 14}}>
          <input
            placeholder='Search Here'
            type="text"
            style={{border: '1px solid #aaa', marginBottom: 5, padding: 5}}
            onChange={e=>{setSearchEmailValue(e.target.value)}}
            value={searchEmailValue}
          />
          <div style={styles.resultbox}>
          {filteredEmails.map((email) => (
            <div key={email.id}>
              <input type="checkbox" onChange={()=>onClick(email.id)}/>
              <span>{email.name}</span>
              <span> - </span>
              <span>{email.email}</span>
            </div>
          ))}
          </div>
        </div>
      </div>}
      {listing === 'groups' && <div style={{padding: 5, marginTop: 5, border: '1px solid #999', fontSize: 14}}>
          <input
            placeholder='Search Here'
            type="text"
            style={{border: '1px solid #aaa', marginBottom: 5, padding: 5}}
            onChange={e=>{setSearchGroupValue(e.target.value)}}
            value={searchGroupValue}
          />
          <div style={styles.resultbox}>
          {filteredGroups.map((group) => (
            <div key={group.id}>
              <input type="checkbox" onChange={()=>onClick(group.id)}/>
              <span>{group.name}</span>
            </div>
          ))}
          </div>
      </div>}
      <div><button onClick={()=>proceed(selectedItems)} style={{cursor: selectedItems.items.length === 0 ? 'not-allowed':'pointer', marginTop: 5, padding: 5, border: 'none', backgroundColor: selectedItems.items.length === 0 ? '#666' : '#007BFF', color: 'white'}} disabled={selectedItems.items.length === 0}>Proceed</button></div>
      {selectedItems.items.length === 0 && <small>Select contacts or gropups to proceed.</small>}
    </div>
  )
}


function SendEmail({ availableTemplates, myTemplates, emailServiceInfo }) {
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const [emailServiceData, setEmailServiceData] = useState();
  const [dataInput, setDataInput] = useState('FORM');
  const [templateData, setTemplateData] = useState();
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(Object.keys(categories)[0]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(categories[Object.keys(categories)[0]][0]);
  const [selectedTheme, setSelectedTheme] = useState("default");
  const [loading, setLoading] = useState(false); // New state for loading

  const structure = (templateData && selectedCategory && selectedSubcategory && templateData[selectedCategory][selectedSubcategory]) || undefined
  const [uploadedData, setUploadedData] = useState(null);

  const updateProfileData = (field, value) => {
    setEmailServiceData({...emailServiceData, [field]: value})
  }

  const handleSendEmail = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem('tk')).access;
    if (dataInput === 'FORM') {
      setLoading(true); // Set loading to true when sending email
      const componentHTML = await getComponentHTML();
      try {
        const response = await axios.post(
          `${API_BASE_URL}plugin/emailplugin/`,
          {
            sender: emailServiceData.default_sender_email_address,
            recipients: emailServiceData.recipients,
            custom_recipients: emailServiceData.custom_recipients,
            subject:emailServiceInfo?.email_subject_prefix + ' '+ emailServiceData.subject,
            body_html: componentHTML,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMessage(response.data.message);
      } catch (error) {
        setMessage('Failed to send email');
        console.error('Error sending email', error);
      } finally {
        setLoading(false); // Set loading to false after response is received
      }
    } else if (dataInput === 'CSV') {
      const parse_csv = parseCsvToData(uploadedData)
      try {
        const response = await axios.post(
          `${API_BASE_URL}plugin/emailplugin/?type=csv`,
          {customized_emails_csv: parse_csv.map((item, idx)=>{
            const EmailPreview = SubcategoryComponent && templateData && (
              <SubcategoryComponent emailStyles={emailStyles} values={emailServiceData} defaultData={item} />
            );
            const getComponentHTML = () => {
              if (!selectedCategory || !selectedSubcategory) return;
              const componentHTML = ReactDOMServer.renderToStaticMarkup(EmailPreview);
              return componentHTML;
            };
            const componentHTML = getComponentHTML();
            return({
              sender: emailServiceData.default_sender_email_address,
              recipients: item['RECEIPIENTS'],
              custom_recipients: null,
              subject:emailServiceInfo?.email_subject_prefix + ' '+ item['EMAIL SUBJECT'],
              body_html: componentHTML,
            })
          })}
          ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMessage(response.data.message);
      } catch (error) {
        setMessage('Failed to send email');
        console.error('Error sending email', error);
      } finally {
        setLoading(false); // Set loading to false after response is received
      }
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubcategory(categories[event.target.value][0]); // Clear subcategory when category changes
  };

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleThemeChange = (event) => {
    setSelectedTheme(event.target.value);
  };

  useEffect(() => {
    setTemplateData(initialTemplateData);
  }, []);

  useEffect(() => {
    if (emailServiceInfo) {
      setEmailServiceData({...emailServiceInfo });
    }
  }, [emailServiceInfo]);

  const emailStyles = getEmailStyles(selectedTheme);

  const SubcategoryComponent = useMemo(() => {
    if (selectedCategory && selectedSubcategory) {
      return lazy(() => import(`./layouts/${selectedCategory}/${selectedSubcategory}`));
    }
    return null;
  }, [selectedCategory, selectedSubcategory]);

  const handleTemplateDataChange = (newData) => {
    setTemplateData({
      ...templateData,
      [selectedCategory]: {
        ...templateData[selectedCategory],
        [selectedSubcategory]: newData
      }
    });
  };

  const EmailPreview = SubcategoryComponent && templateData && (
    <SubcategoryComponent emailStyles={emailStyles} values={emailServiceData} defaultData={structure} />
  );

  const getComponentHTML = async () => {
    if (!selectedCategory || !selectedSubcategory) return;
    const componentHTML = ReactDOMServer.renderToStaticMarkup(EmailPreview);
    return componentHTML;
  };

  const chooseFromMyContacts = () => {
    updateProfileData('recipients', '')
    setIsOpen(true)
  }
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ flex: 1, padding: '10px', backgroundColor: '#eee', overflowY:'auto'}}>
        <h3>Select Email Template</h3>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>
            <div><small><b>THEME</b></small></div>
            <select value={selectedTheme} onChange={handleThemeChange} style={{padding: 5}}>
              <option value="default">Default</option>
              <option value="dark">Dark</option>
              <option value="light">Light</option>
            </select>
          </label>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>
            <div><small><b>EMAIL TEMPLATE CATEGORY</b></small></div>
            <select value={selectedCategory} onChange={handleCategoryChange} style={{padding: 5}}>
              {Object.keys(categories).map((category) => (
                <option key={category} value={category}>{category.replace(/([A-Z])/g, ' $1').trim()}</option>
              ))}
            </select>
          </label>
        </div>
        <div style={{ marginBottom: '10px' }}>
            <div><small><b>EMAIL TEMPLATE</b></small></div>
            {selectedCategory && categories[selectedCategory].map((subcategory) => (
              <div key={subcategory}>
                <button
                  style={{
                    marginRight: '5px',
                    marginBottom: '5px',
                    padding: '8px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    backgroundColor: selectedSubcategory === subcategory ? '#007bff' : '#fff',
                    color: selectedSubcategory === subcategory ? '#fff' : '#000',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSubcategoryChange(subcategory)}
                >
                  {subcategory.replace(/([A-Z])/g, ' $1').trim()}
                </button>
              </div>
            ))}
        </div>
        {availableTemplates && <div style={{border: '1px solid #999', padding: 5, backgroundColor: 'white'}}><small>You can edit the 'Email Information' and <b>create</b> your own template.</small></div>}
        {myTemplates && <div style={{border: '1px solid #999', padding: 5, backgroundColor: 'white'}}><small>You can edit the 'Email Information' and <b>update</b> your template.</small></div>}
        {(!availableTemplates && !myTemplates) && <div>
          <h3>Sender and Receiver</h3>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <div><small><b>RECEIVING USER NAME</b></small></div>
              <input
                value={emailServiceData?.default_receiving_user_name}
                onChange={(e) => updateProfileData('default_receiving_user_name', e.target.value)}
                style={{ padding: '5px', width: '200px' }}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <div><small><b>SENDER COMPANY NAME</b></small></div>
              <input
                value={emailServiceData?.sender_company_name}
                onChange={(e) => updateProfileData('sender_company_name', e.target.value)}
                style={{ padding: '5px', width: '200px' }}
              />
            </div>
          </div>
        </div>}

        {(!availableTemplates && !myTemplates) && <div style={{padding: 10, backgroundColor: '#ddd'}}>
          <h3>Send Email</h3>
            {/*<small>Use:{' '}
              <button style={{background: dataInput === 'FORM' ? '#99F' : 'none'}} onClick={()=>setDataInput('FORM')}>Form</button>
              <button style={{background: dataInput === 'CSV' ? '#99F' : 'none'}} onClick={()=>setDataInput('CSV')}>CSV Template</button>
            </small>*/}
              <div style={{ marginBottom: '10px' }}>
                <div><small><b>SENDER</b></small></div>
                <input
                  type="email"
                  value={emailServiceData?.default_sender_email_address}
                  onChange={(e) => updateProfileData('default_sender_email_address', e.target.value)}
                  style={{ padding: '5px' }}
                  disabled
                />
              </div>
              {dataInput === 'CSV' && <div style={{ marginBottom: '10px' }}>
                <div><small><b>USER INFO TEMPLATE</b></small></div>
                <div><small>
                  <CsvDownloadComponent/>
                  <CsvUploadComponent {...{csvData, setCsvData}}/>
                </small></div>
              </div>}
              {dataInput === 'CSV' && <div style={{ marginBottom: '10px' }}>
                <div><small><b>EMAIL TEMPLATE</b></small></div>
                <div><small>
                  {csvData.length === 0 && <div style={{color: '#c3092b'}}>Upload the user info CSV file to generate the email template.</div>}
                  {csvData.length !== 0 && <CsvComponent {...{structure, uploadedData, setUploadedData, csvData}}/>}
                </small></div>
              </div>}
              {dataInput === 'FORM' && <div>
              <div style={{ marginBottom: '10px' }}>
                <Modal {...{isOpen, setIsOpen}}>
                  <SelectContacts
                    proceed={(selectedItems)=>{
                      setIsOpen(false);
                      updateProfileData('custom_recipients', selectedItems)
                    }}/>
                </Modal>
                <div><small><b>RECIPIENTS (EMAIL)</b></small></div>
                {emailServiceData?.custom_recipients && <div>Selected {emailServiceData?.custom_recipients?.items?.length} {emailServiceData?.custom_recipients?.type}</div>}
                {emailServiceData?.custom_recipients && <button onClick={()=>updateProfileData('custom_recipients', null)} style={{ fontSize: 11, border: 'none', backgroundColor: '#BA2C2C', color: 'white', borderRadius: 5, padding: 5, cursor:'pointer'}}>Clear selections</button>}
                <button onClick={chooseFromMyContacts} style={{ fontSize: 11, border: 'none', backgroundColor: '#007BFF', color: 'white', borderRadius: 5, padding: 5, cursor:'pointer', marginBottom: 5}}>Get emails from <b>My Contacts</b></button>
                {!emailServiceData?.custom_recipients && <input
                  placeholder="Emails separated by comma"
                  value={emailServiceData?.recipients}
                  onChange={(e) => updateProfileData('recipients', e.target.value)}
                  style={{ padding: '5px' }}
                />}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <div><small><b>EMAIL SUBJECT<br/><small>{emailServiceInfo?.email_subject_prefix+' '}</small></b></small></div>
                <input
                  type="text"
                  value={emailServiceData?.subject}
                  onChange={(e) => updateProfileData('subject', e.target.value)}
                  style={{ padding: '5px' }}
                  placeholder="Email Subject"
                />
              </div>
            </div>}
            {message && <p style={{border: '1px solid #999', padding: 5, color: '#ccc', backgroundColor: '#333'}}><small><small>{message}</small><button style={{marginLeft: 10}} onClick={()=>navigate('/email-history')}><small>Check History</small></button></small></p>}
            <button onClick={handleSendEmail} style={{ padding: '8px 16px', backgroundColor: loading ? '#777':'#007bff', color: '#fff', border: 'none', cursor: 'pointer' }} disabled={loading}>
              {loading ? 'Loading...' : 'Send Email'}
            </button>
        </div>}
      </div>
      {dataInput === 'FORM' && <div style={{ flex: 1, padding: '10px', backgroundColor: '#ccc', overflowY:'auto' }}>
        {templateData && <DynamicForm data={structure} onChange={handleTemplateDataChange} createTemplates={availableTemplates} updateTemplates={myTemplates} />}
      </div>}
      {templateData && <div style={{ flex: 3, overflowY:'auto' }}>
        <LayoutSelector
          selectedCategory={selectedCategory}
          selectedSubcategory={selectedSubcategory}
        >
          <div style={{fontSize: 13, position: 'sticky', top: 0,display: 'flex', padding: 10, backgroundColor: 'white', boxShadow: '0px 0px 5px #999'}}><div style={{padding: 5}}>Subject</div><div style={{border: '1px solid #ccc', flex: 1, padding: 5}}>{emailServiceInfo?.email_subject_prefix + ' '+ (emailServiceData?.subject || '')}</div></div>
          {EmailPreview}
        </LayoutSelector>
      </div>}
    </div>
  );
}


const styles = {
  th: {
    border: '1px solid #ccc',
    padding: '10px',
    background: '#f4f4f4',
    cursor: 'pointer',
    position: 'sticky',
    top: 0
  },
  td: {
    border: '1px solid #ccc',
    padding: '10px',
  },
  resultbox: {
    maxHeight: 100,
    overflowY: 'auto'
  }
};

export default SendEmail;
