import React from 'react';

const AccountVerification = ({ values, defaultData, emailStyles }) => {
  const styles = emailStyles

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1>Account Verification</h1>
      </div>
      <div style={styles.body}>
        <p>Dear {values?.default_receiving_user_name},</p>
        <p>
          {defaultData?.messages[0]}
        </p>
        <a href={defaultData?.buttons[0].link} style={styles.button}>{defaultData?.buttons[0].label}</a>
        <p>
          {defaultData?.messages[1]}
        </p>
      </div>
      <div style={styles.footer}>
        <p>Thank you for joining us!</p>
        <p>&copy; {values?.currentYear} {values?.sender_company_name}. All rights reserved.</p>
      </div>
    </div>
  );
};

export default AccountVerification;
