const PAGE_LIMIT = 8
const BIG_LIMIT = 1000

export const configureAccountUserprofile = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    userprofile_fetch_list: state["account"]?.userprofile_list,
    userprofile_fetch_item: state["account"]?.userprofile_item,
    userprofile_create_item: state["account"]?.userprofile_item,
    userprofile_update_item: state["account"]?.userprofile_item,
    userprofile_delete_item: state["account"]?.deleted_userprofile,
    userprofile_loading: state['account']?.loading,
  },
  action: {
    fetchUserprofileList: () => {
      const userprofile_fetch_list_request = {
        dispatch,
        request: ["account", "userprofile", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(userprofile_fetch_list_request);
    },
    fetchUserprofileItem: (id) => {
      const userprofile_fetch_item_request = {
        dispatch,
        request: ["account", "userprofile", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(userprofile_fetch_item_request);
    },
    createUserprofileItem: (payload) => {
      const userprofile_create_item_request = {
        dispatch,
        request: ["account", "userprofile", "create"],
        request_info: payload,
        auth: true,
      };
      utils.action(userprofile_create_item_request);
    },
    updateUserprofileItem: (user_id, payload) => {
      let request_info;
      if (typeof payload.profile_pic === "string") {
        const { profile_pic, ...remainingData } = payload;

        request_info = { lookup_field: "user_id", user_id, ...remainingData };
      } else {
        request_info = { lookup_field: "user_id", user_id, ...payload };
      }
      const userprofile_update_item_request = {
        dispatch,
        request: ["account", "userprofile", "update"],
        request_info,
        auth: true,
      };
      utils.action(userprofile_update_item_request);
    },
    deleteUserprofileItem: (id) => {
      const userprofile_delete_item_request = {
        dispatch,
        request: ["account", "userprofile", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(userprofile_delete_item_request);
    },
  },
});

export const configureAccountUsermessagedispatch = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    usermessagedispatch_fetch_list: state['account']?.usermessagedispatch_list,
    usermessagedispatch_fetch_item: state['account']?.usermessagedispatch_item,
    usermessagedispatch_create_item: state['account']?.usermessagedispatch_item,
    usermessagedispatch_update_item: state['account']?.usermessagedispatch_item,
    usermessagedispatch_delete_item: state['account']?.deleted_usermessagedispatch,
    usermessagedispatch_loading: state['account']?.loading,
  },
  action: {
    fetchUsermessagedispatchList: () => {
      const usermessagedispatch_fetch_list_request = { dispatch, request: ['account', 'usermessagedispatch', 'list'], request_info: {}, auth: false}
      utils.action(usermessagedispatch_fetch_list_request)
    },
    fetchUsermessagedispatchItem: (id) => {
      const usermessagedispatch_fetch_item_request = { dispatch, request: ['account', 'usermessagedispatch', 'read'], request_info: {id}, auth: false}
      utils.action(usermessagedispatch_fetch_item_request)
    },
    createUsermessagedispatchItem: (payload) => {
      const usermessagedispatch_create_item_request = { dispatch, request: ['account', 'usermessagedispatch', 'create'], request_info: payload, auth: true}
      utils.action(usermessagedispatch_create_item_request)
    },
    updateUsermessagedispatchItem: (id, payload) => {
      const usermessagedispatch_update_item_request = { dispatch, request: ['account', 'usermessagedispatch', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(usermessagedispatch_update_item_request)
    },
    deleteUsermessagedispatchItem: (id) => {
      const usermessagedispatch_delete_item_request = { dispatch, request: ['account', 'usermessagedispatch', 'delete'], request_info: {id}, auth: false}
      utils.action(usermessagedispatch_delete_item_request)
    },
  }
})
