import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {API_BASE_URL} from 'App'

const EmailVerification = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const reason = 'ACCOUNT_VERIFICATION'
        const medium = 'EMAIL'
        const response = await axios.post(`${API_BASE_URL}account/verify/`, { key, medium, reason });
        setVerificationStatus(true);
      } catch (error) {
        setErrorMessage('Verification failed. Please try again.');
      }
    };
    verifyEmail();
  }, [key]);

  return (
    <div style={styles.container}>
      {(verificationStatus === null && errorMessage === null) && <p style={styles.message}>Verifying your email...</p>}
      {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
        <div style={styles.successContainer}>
          {verificationStatus === true && <p style={styles.successMessage}>Your email has been successfully verified!</p>}
          <button style={styles.button} onClick={() => navigate('/')}>Go to Dashboard</button>
        </div>
      {verificationStatus === false && <p style={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f4f8',
    fontFamily: 'Arial, sans-serif',
  },
  message: {
    fontSize: '18px',
    color: '#555',
  },
  errorMessage: {
    color: 'red',
    fontSize: '16px',
    backgroundColor: '#fee',
    padding: '10px 20px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#e0ffe0',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  successMessage: {
    color: '#4CAF50',
    fontSize: '18px',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007BFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default EmailVerification;
