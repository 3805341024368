const detailedFeatures = [
  {
    icon: '😊',
    title: 'Easy to Use',
    description: 'Our user-friendly interface allows you to create and send emails with just a few clicks.',
  },
  {
    icon: '📄',
    title: 'Template Based',
    description: 'Choose from a variety of templates.',
  },
  {
    icon: '📇',
    title: 'Manage Contacts Efficiently',
    description: 'Easily manage your email contacts and segment them for targeted campaigns.',
  },
  {
    icon: '🕒',
    title: 'Record Email History',
    description: 'Keep track of your email history and performance with detailed reports.',
  },
  {
    icon: '🔒',
    title: 'Secure and Reliable',
    description: 'Our platform is secure and reliable, ensuring your emails are delivered safely.',
  },
  {
    icon: '⏰',
    title: '24/7 Support',
    description: 'Our support team is available 24/7 to help you with any issues you may have.',
  },
];

const FeatureItem = ({ icon, title, description }) => (
  <div style={styles.featureItem}>
    <div style={styles.icon}>{icon}</div>
    <h3 style={styles.featureTitle}>{title}</h3>
    <p style={styles.featureDescription}>{description}</p>
  </div>
);

const Features = () => {
  return(
    <section id="features" style={styles.featuresSection}>
      <h2 style={styles.sectionTitle}>Features</h2>
      <div style={styles.featuresList}>
        {detailedFeatures.map((feature, index) => (
          <FeatureItem
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>
    </section>
  )
}

const styles = {
  featuresSection: {
    padding: '50px 20px',
  },
  sectionTitle: {
    fontSize: '28px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  featuresList: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  featureItem: {
    flex: '1 1 30%',
    textAlign: 'center',
    margin: 10,
    borderRadius: 10,
    border: '1px solid #ccc',
    backgroundColor: '#ffffffAA',
    color: '#1C3144'
  },
  icon: {
    fontSize: '48px',  // Adjust size as needed
    marginBottom: '10px',
    marginTop: '20px'
  },
  featureTitle: {
    fontSize: '24px',
    marginBottom: '10px',
    padding: 10,
    color: '#0D0C1D'
  },
  featureDescription: {
    fontSize: '14px',
    padding: 10
  },
};

export default Features;
