import {NavBar} from 'components/NavBar'
import {Footer} from 'components/Footer'

const MainLayout = ({children}) => {
  return(
    <div style={{ height: '100vh', overflowY: 'auto' }}>
      <div style={{ position: 'sticky', top: 0}}><NavBar/></div>
      <div style={{minHeight: '90vh'}}>{children}</div>
      <div style={{ position: 'sticky', bottom: 0}}><Footer/></div>
    </div>
  )
}
export default MainLayout
