import React from 'react';
import { useNavigate } from 'react-router-dom';
import Faq from 'components/Faq';
import Pricing from 'components/Pricing';
import Features from 'components/Features';
import Contact from 'components/Contact';

const LandingPage = () => {
  const navigate = useNavigate('/navigate');
  return (
    <div style={styles.container}>
      <div style={styles.hero}>
        <section style={{ display: 'flex', maxWidth: 1024, margin: 'auto' }}>
          <div style={{ flex: 2 }}>
            <h2 style={styles.heroTitle}>Send Emails: Reach Your Audience with TrustMailService</h2>
            <p style={styles.heroSubtitle}>Effortless email campaigns for businesses of all sizes</p>
            <button style={styles.getStartedButton} onClick={() => navigate('/register')}>Get Started</button>
          </div>
          <div style={styles.quickPdfTweak}>
            <div style={styles.quickPdfTitle}>Free Tool: Quick PDF Tweak</div>
            <div style={styles.quickPdfLinks}>
              <a href="/pdf/merge-pdf" style={styles.quickPdfLink}>Merge PDFs</a>
              <a href="/pdf/pdf-to-image" style={styles.quickPdfLink}>PDF to Image</a>
              <a href="/pdf/images-to-pdf" style={styles.quickPdfLink}>Images to PDF</a>
              <a href="/pdf/split-pdf" style={styles.quickPdfLink}>Split PDF</a>
              <a href="/pdf/pdf-text-extraction" style={styles.quickPdfLink}>PDF Text Extraction</a>
              <a href="/pdf/add-watermark-text" style={styles.quickPdfLink}>Watermark Text to PDF</a>
              <a href="/pdf/add-image-to-pdf-pages" style={styles.quickPdfLink}>Image to PDF pages</a>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Features />
      </div>
      <div style={{ backgroundColor: '#DDE1E466' }}>
        <Pricing />
      </div>
      <Faq />
      <div style={{ background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', color: 'white' }}>
        <Contact />
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#0D0C1D',
    lineHeight: '1.6',
    background: 'linear-gradient(135deg, #e0e7ff 0%, #d5d9ff 20%, #c5c7ff 40%, #b4b4ff 60%, #a3a1ff 80%, #928eff 100%)',
  },
  hero: {
    padding: '50px 20px',
    color: 'white',
    background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', // Gradient background
  },
  heroTitle: {
    fontSize: '36px',
    margin: '20px 0',
  },
  heroSubtitle: {
    fontSize: '20px',
    marginBottom: '30px',
  },
  getStartedButton: {
    padding: '10px 30px',
    backgroundColor: '#fff',
    color: '#7257B3',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  quickPdfTweak: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#f4f4f9',
    fontFamily: 'Arial, sans-serif',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: 'auto',
  },
  quickPdfTitle: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: '20px',
    color: '#333',
  },
  quickPdfLinks: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    width: '100%',
  },
  quickPdfLink: {
    textDecoration: 'none',
    color: '#007bff',
    fontSize: '13px',
    padding: '5px',
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: '28px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  newsletterSection: {
    padding: '50px 20px',
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  newsletterText: {
    marginBottom: '20px',
  },
  newsletterForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  newsletterInput: {
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #dee2e6',
    borderRadius: '5px',
  },
  newsletterButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default LandingPage;
