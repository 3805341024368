import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'plugin',
  initialState: { loading: false },
  reducers: {
    
    // EMAILPLUGIN REDUCER FUNCTION

    // EMAILPLUGIN FETCH LIST
    fetchPluginEmailpluginListStart: stateLoadingStart,
    fetchPluginEmailpluginListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailplugin_list`),
    fetchPluginEmailpluginListFailure: stateError,

    // EMAILPLUGIN FETCH ITEM
    fetchPluginEmailpluginItemStart: stateLoadingStart,
    fetchPluginEmailpluginItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailplugin_item`),
    fetchPluginEmailpluginItemFailure: stateError,

    // EMAILPLUGIN CREATE ITEM
    createPluginEmailpluginItemStart: stateLoadingStart,
    createPluginEmailpluginItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailplugin_item`),
    createPluginEmailpluginItemFailure: stateError,

    // EMAILPLUGIN UPDATE ITEM
    updatePluginEmailpluginItemStart: stateLoadingStart,
    updatePluginEmailpluginItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailplugin_item`),
    updatePluginEmailpluginItemFailure: stateError,

    // EMAILPLUGIN DELETE ITEM
    deletePluginEmailpluginItemStart: stateLoadingStart,
    deletePluginEmailpluginItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_emailplugin`),
    deletePluginEmailpluginItemFailure: stateError,


    // EMAILSERVICE REDUCER FUNCTION

    // EMAILSERVICE FETCH LIST
    fetchPluginEmailserviceListStart: stateLoadingStart,
    fetchPluginEmailserviceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailservice_list`),
    fetchPluginEmailserviceListFailure: stateError,

    // EMAILSERVICE FETCH ITEM
    fetchPluginEmailserviceItemStart: stateLoadingStart,
    fetchPluginEmailserviceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailservice_item`),
    fetchPluginEmailserviceItemFailure: stateError,

    // EMAILSERVICE CREATE ITEM
    createPluginEmailserviceItemStart: stateLoadingStart,
    createPluginEmailserviceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailservice_item`),
    createPluginEmailserviceItemFailure: stateError,

    // EMAILSERVICE UPDATE ITEM
    updatePluginEmailserviceItemStart: stateLoadingStart,
    updatePluginEmailserviceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailservice_item`),
    updatePluginEmailserviceItemFailure: stateError,

    // EMAILSERVICE DELETE ITEM
    deletePluginEmailserviceItemStart: stateLoadingStart,
    deletePluginEmailserviceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_emailservice`),
    deletePluginEmailserviceItemFailure: stateError,


    // EMAILHISTORY REDUCER FUNCTION

    // EMAILHISTORY FETCH LIST
    fetchPluginEmailhistoryListStart: stateLoadingStart,
    fetchPluginEmailhistoryListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailhistory_list`),
    fetchPluginEmailhistoryListFailure: stateError,

    // EMAILHISTORY FETCH ITEM
    fetchPluginEmailhistoryItemStart: stateLoadingStart,
    fetchPluginEmailhistoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailhistory_item`),
    fetchPluginEmailhistoryItemFailure: stateError,

    // EMAILHISTORY CREATE ITEM
    createPluginEmailhistoryItemStart: stateLoadingStart,
    createPluginEmailhistoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailhistory_item`),
    createPluginEmailhistoryItemFailure: stateError,

    // EMAILHISTORY UPDATE ITEM
    updatePluginEmailhistoryItemStart: stateLoadingStart,
    updatePluginEmailhistoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailhistory_item`),
    updatePluginEmailhistoryItemFailure: stateError,

    // EMAILHISTORY DELETE ITEM
    deletePluginEmailhistoryItemStart: stateLoadingStart,
    deletePluginEmailhistoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_emailhistory`),
    deletePluginEmailhistoryItemFailure: stateError,


    // EMAILSUBSCRIPTION REDUCER FUNCTION

    // EMAILSUBSCRIPTION FETCH LIST
    fetchPluginEmailsubscriptionListStart: stateLoadingStart,
    fetchPluginEmailsubscriptionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailsubscription_list`),
    fetchPluginEmailsubscriptionListFailure: stateError,

    // EMAILSUBSCRIPTION FETCH ITEM
    fetchPluginEmailsubscriptionItemStart: stateLoadingStart,
    fetchPluginEmailsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailsubscription_item`),
    fetchPluginEmailsubscriptionItemFailure: stateError,

    // EMAILSUBSCRIPTION CREATE ITEM
    createPluginEmailsubscriptionItemStart: stateLoadingStart,
    createPluginEmailsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailsubscription_item`),
    createPluginEmailsubscriptionItemFailure: stateError,

    // EMAILSUBSCRIPTION UPDATE ITEM
    updatePluginEmailsubscriptionItemStart: stateLoadingStart,
    updatePluginEmailsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailsubscription_item`),
    updatePluginEmailsubscriptionItemFailure: stateError,

    // EMAILSUBSCRIPTION DELETE ITEM
    deletePluginEmailsubscriptionItemStart: stateLoadingStart,
    deletePluginEmailsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_emailsubscription`),
    deletePluginEmailsubscriptionItemFailure: stateError,


    // EMAILGROUP REDUCER FUNCTION

    // EMAILGROUP FETCH LIST
    fetchPluginEmailgroupListStart: stateLoadingStart,
    fetchPluginEmailgroupListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailgroup_list`),
    fetchPluginEmailgroupListFailure: stateError,

    // EMAILGROUP FETCH ITEM
    fetchPluginEmailgroupItemStart: stateLoadingStart,
    fetchPluginEmailgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailgroup_item`),
    fetchPluginEmailgroupItemFailure: stateError,

    // EMAILGROUP CREATE ITEM
    createPluginEmailgroupItemStart: stateLoadingStart,
    createPluginEmailgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailgroup_item`),
    createPluginEmailgroupItemFailure: stateError,

    // EMAILGROUP UPDATE ITEM
    updatePluginEmailgroupItemStart: stateLoadingStart,
    updatePluginEmailgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `emailgroup_item`),
    updatePluginEmailgroupItemFailure: stateError,

    // EMAILGROUP DELETE ITEM
    deletePluginEmailgroupItemStart: stateLoadingStart,
    deletePluginEmailgroupItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_emailgroup`),
    deletePluginEmailgroupItemFailure: stateError,


    // EMAIL REDUCER FUNCTION

    // EMAIL FETCH LIST
    fetchPluginEmailListStart: stateLoadingStart,
    fetchPluginEmailListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `email_list`),
    fetchPluginEmailListFailure: stateError,

    // EMAIL FETCH ITEM
    fetchPluginEmailItemStart: stateLoadingStart,
    fetchPluginEmailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `email_item`),
    fetchPluginEmailItemFailure: stateError,

    // EMAIL CREATE ITEM
    createPluginEmailItemStart: stateLoadingStart,
    createPluginEmailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `email_item`),
    createPluginEmailItemFailure: stateError,

    // EMAIL UPDATE ITEM
    updatePluginEmailItemStart: stateLoadingStart,
    updatePluginEmailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `email_item`),
    updatePluginEmailItemFailure: stateError,

    // EMAIL DELETE ITEM
    deletePluginEmailItemStart: stateLoadingStart,
    deletePluginEmailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_email`),
    deletePluginEmailItemFailure: stateError,


  },
});

export const {
  fetchPluginEmailpluginListStart, fetchPluginEmailserviceListStart, fetchPluginEmailhistoryListStart, fetchPluginEmailsubscriptionListStart, fetchPluginEmailgroupListStart, fetchPluginEmailListStart,
  fetchPluginEmailpluginListSuccess, fetchPluginEmailserviceListSuccess, fetchPluginEmailhistoryListSuccess, fetchPluginEmailsubscriptionListSuccess, fetchPluginEmailgroupListSuccess, fetchPluginEmailListSuccess,
  fetchPluginEmailpluginListFailure, fetchPluginEmailserviceListFailure, fetchPluginEmailhistoryListFailure, fetchPluginEmailsubscriptionListFailure, fetchPluginEmailgroupListFailure, fetchPluginEmailListFailure,
  fetchPluginEmailpluginItemStart, fetchPluginEmailserviceItemStart, fetchPluginEmailhistoryItemStart, fetchPluginEmailsubscriptionItemStart, fetchPluginEmailgroupItemStart, fetchPluginEmailItemStart,
  fetchPluginEmailpluginItemSuccess, fetchPluginEmailserviceItemSuccess, fetchPluginEmailhistoryItemSuccess, fetchPluginEmailsubscriptionItemSuccess, fetchPluginEmailgroupItemSuccess, fetchPluginEmailItemSuccess,
  fetchPluginEmailpluginItemFailure, fetchPluginEmailserviceItemFailure, fetchPluginEmailhistoryItemFailure, fetchPluginEmailsubscriptionItemFailure, fetchPluginEmailgroupItemFailure, fetchPluginEmailItemFailure,
  createPluginEmailpluginItemStart, createPluginEmailserviceItemStart, createPluginEmailhistoryItemStart, createPluginEmailsubscriptionItemStart, createPluginEmailgroupItemStart, createPluginEmailItemStart,
  createPluginEmailpluginItemSuccess, createPluginEmailserviceItemSuccess, createPluginEmailhistoryItemSuccess, createPluginEmailsubscriptionItemSuccess, createPluginEmailgroupItemSuccess, createPluginEmailItemSuccess,
  createPluginEmailpluginItemFailure, createPluginEmailserviceItemFailure, createPluginEmailhistoryItemFailure, createPluginEmailsubscriptionItemFailure, createPluginEmailgroupItemFailure, createPluginEmailItemFailure,
  updatePluginEmailpluginItemStart, updatePluginEmailserviceItemStart, updatePluginEmailhistoryItemStart, updatePluginEmailsubscriptionItemStart, updatePluginEmailgroupItemStart, updatePluginEmailItemStart,
  updatePluginEmailpluginItemSuccess, updatePluginEmailserviceItemSuccess, updatePluginEmailhistoryItemSuccess, updatePluginEmailsubscriptionItemSuccess, updatePluginEmailgroupItemSuccess, updatePluginEmailItemSuccess,
  updatePluginEmailpluginItemFailure, updatePluginEmailserviceItemFailure, updatePluginEmailhistoryItemFailure, updatePluginEmailsubscriptionItemFailure, updatePluginEmailgroupItemFailure, updatePluginEmailItemFailure,
  deletePluginEmailpluginItemStart, deletePluginEmailserviceItemStart, deletePluginEmailhistoryItemStart, deletePluginEmailsubscriptionItemStart, deletePluginEmailgroupItemStart, deletePluginEmailItemStart,
  deletePluginEmailpluginItemSuccess, deletePluginEmailserviceItemSuccess, deletePluginEmailhistoryItemSuccess, deletePluginEmailsubscriptionItemSuccess, deletePluginEmailgroupItemSuccess, deletePluginEmailItemSuccess,
  deletePluginEmailpluginItemFailure, deletePluginEmailserviceItemFailure, deletePluginEmailhistoryItemFailure, deletePluginEmailsubscriptionItemFailure, deletePluginEmailgroupItemFailure, deletePluginEmailItemFailure
} = slice.actions;

export default slice.reducer;
