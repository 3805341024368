export const themeColors = {
  default: {
    primaryBackgroundColor: '#007bff',
    primaryTextColor: 'white',
    textColor: '#333',
    containerBackgroundColor: 'white',
    backgroundColor: '#f4f4f4',
    footerTextColor: '#777',
  },
  dark: {
    primaryBackgroundColor: '#111',
    primaryTextColor: 'white',
    textColor: '#ccc',
    containerBackgroundColor: '#222',
    backgroundColor: '#333',
    footerTextColor: '#999',
  },
  light: {
    primaryBackgroundColor: '#f0f0f0',
    primaryTextColor: '#333',
    textColor: '#666',
    containerBackgroundColor: 'white',
    backgroundColor: '#f9f9f9',
    footerTextColor: '#888',
  }
};

export const getEmailStyles = (selectedTheme) => {
  return({
    container: {
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      color: themeColors[selectedTheme].textColor,
      padding: '20px',
      backgroundColor: themeColors[selectedTheme].backgroundColor,
    },
    header: {
      backgroundColor: themeColors[selectedTheme].primaryBackgroundColor,
      color: themeColors[selectedTheme].primaryTextColor,
      padding: '10px',
      textAlign: 'center',
    },
    body: {
      padding: '20px',
      backgroundColor: themeColors[selectedTheme].containerBackgroundColor,
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    footer: {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '12px',
      color: themeColors[selectedTheme].footerTextColor,
    },
    button: {
      display: 'inline-block',
      padding: '10px 20px',
      marginTop: '20px',
      backgroundColor: themeColors[selectedTheme].primaryBackgroundColor,
      color: themeColors[selectedTheme].primaryTextColor,
      textDecoration: 'none',
      borderRadius: '4px',
    },
  })
}
