export const Footer = ({minimal}) => {
  return(
    <div style={styles.footer}>
      <div style={styles.footerText}>
      Copyright © {new Date().getFullYear()} Corpola Tech Pvt. Ltd. - All rights reserved.
      </div>
    </div>
  )
}

const styles = {
  footer: {
    padding: '1px',
    backgroundColor: '#0D0C1D',
    textAlign: 'center',
    position: 'sticky',
    bottom: 0,
  },
  footerText: {
    color: 'white',
    fontSize: 12
  },
};
