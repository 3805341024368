import axios from '../utils/axios';

import {fetchChatChatgroupListStart, fetchChatChatgroupListSuccess, fetchChatChatgroupListFailure} from '../features/chat'
import {fetchChatChatgroupItemStart, fetchChatChatgroupItemSuccess, fetchChatChatgroupItemFailure} from '../features/chat'
import {createChatChatgroupItemStart, createChatChatgroupItemSuccess, createChatChatgroupItemFailure} from '../features/chat'
import {updateChatChatgroupItemStart, updateChatChatgroupItemSuccess, updateChatChatgroupItemFailure} from '../features/chat'
import {deleteChatChatgroupItemStart, deleteChatChatgroupItemSuccess, deleteChatChatgroupItemFailure} from '../features/chat'

import {fetchChatMessageListStart, fetchChatMessageListSuccess, fetchChatMessageListFailure} from '../features/chat'
import {fetchChatMessageItemStart, fetchChatMessageItemSuccess, fetchChatMessageItemFailure} from '../features/chat'
import {createChatMessageItemStart, createChatMessageItemSuccess, createChatMessageItemFailure} from '../features/chat'
import {updateChatMessageItemStart, updateChatMessageItemSuccess, updateChatMessageItemFailure} from '../features/chat'
import {deleteChatMessageItemStart, deleteChatMessageItemSuccess, deleteChatMessageItemFailure} from '../features/chat'


// CHATGROUP ACTIONS
export const fetchChatChatgroupList = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatChatgroupListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/chatgroup/`, { params: info.params, headers: info.headers });
        dispatch(fetchChatChatgroupListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatChatgroupListFailure({key, error: error.message}));
    }
}
export const fetchChatChatgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatChatgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/chatgroup/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchChatChatgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatChatgroupItemFailure({key, error: error.message}));
    }
}
export const createChatChatgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(createChatChatgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/chat/chatgroup/`, info.payload, { headers: info.headers });
        dispatch(createChatChatgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createChatChatgroupItemFailure({key, error: error.message}));
    }
}
export const updateChatChatgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(updateChatChatgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/chat/chatgroup/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateChatChatgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateChatChatgroupItemFailure({key, error: error.message}));
    }
}
export const deleteChatChatgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteChatChatgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/chat/chatgroup/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteChatChatgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteChatChatgroupItemFailure({key, error: error.message}));
    }
}


// MESSAGE ACTIONS
export const fetchChatMessageList = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatMessageListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/message/`, { params: info.params, headers: info.headers });
        dispatch(fetchChatMessageListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatMessageListFailure({key, error: error.message}));
    }
}
export const fetchChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatMessageItemFailure({key, error: error.message}));
    }
}
export const createChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(createChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/chat/message/`, info.payload, { headers: info.headers });
        dispatch(createChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createChatMessageItemFailure({key, error: error.message}));
    }
}
export const updateChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(updateChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/chat/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateChatMessageItemFailure({key, error: error.message}));
    }
}
export const deleteChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/chat/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteChatMessageItemFailure({key, error: error.message}));
    }
}




