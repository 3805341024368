import React, { useState, useEffect } from 'react';
import { configurePluginEmailhistory } from "services/access/plugin";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";
import Modal from "components/Modal";

const EmailHistory = ({}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [isModalOpen, setIsModalOpen] = useState({});
  const [sentEmails, setSentEmails] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'descending' });
  const { initialize, data, action } = configurePluginEmailhistory(utils, dispatch, state);

  useEffect(()=>{
    action.fetchEmailhistoryList()
  }, [])

  useEffect(()=>{
    if (data.emailhistory_fetch_list) {
      setSentEmails(data.emailhistory_fetch_list)
    }
  }, [data.emailhistory_fetch_list])

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedEmails = React.useMemo(() => {
    let sortableEmails = [...sentEmails];
    sortableEmails.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortableEmails;
  }, [sentEmails, sortConfig]);

  return (
    <div style={styles.container}>

      <h2 style={styles.header}>Email History</h2>
      <small><b>Sorted by</b>: {sortConfig.key} in {sortConfig.direction} order. (Click on table head to sort)</small>
      <div style={{maxHeight: '70vh', overflowY: 'auto', marginTop: 10}}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th} onClick={() => handleSort('subject')}>Subject</th>
            <th style={styles.th} onClick={() => handleSort('recipients')}>Recipients</th>
            <th style={styles.th} onClick={() => handleSort('timestamp')}>Timestamp</th>
            <th style={styles.th} onClick={() => handleSort('status')}>Status</th>
            <th style={styles.th} onClick={() => handleSort('message')}>Message</th>
            <th style={styles.th} onClick={() => handleSort('email')}>Email</th>
          </tr>
        </thead>
        <tbody>
          {sortedEmails.map((email) => (
            <tr key={email.id}>
              <td style={styles.td}>{email.subject}</td>
              <td style={styles.td}>{email.recipients}</td>
              <td style={styles.td}>{email.timestamp}</td>
              <td style={{...styles.td, color: email.status ==='error' ? '#c3092b' : 'green'}}>{email.status}</td>
              <td style={styles.td}>{email.message}</td>
              <td style={styles.td}>
                <Modal isOpen={isModalOpen[email.id]} setIsOpen={(value)=>setIsModalOpen({[email.id]:value})}>
                  <h3>Subject: {email.subject}</h3>
                  <p><small style={{border: '1px solid grey', backgroundColor: '#eee', padding: 5}}><b>Status</b>: {email.status}</small> <small style={{border: '1px solid grey', padding: 5}}><b>Recipients</b>: {email.recipients}</small></p>
                  <div style={{border:'1px solid #aaa', maxHeight: '40vh', overflowY: 'auto'}} dangerouslySetInnerHTML={{__html: email.email}}></div>
                </Modal>
                <button onClick={()=>setIsModalOpen({[email.id]:true})} style={{background:"#007BFF", color: 'white', border: 'none', cursor: 'pointer', padding: '8px', borderRadius: 4}}>View</button>
              </td>
            </tr>
          ))}
          {sortedEmails.length === 0 && (
            <tr>
              <td colSpan="6" style={styles.td}>No emails sent yet.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </div>
  );
};

const styles = {
  container: {
    fontSize: 13,
    padding: '20px',
    maxWidth: '80%',
    margin: 'auto',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '50px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #ccc',
    padding: '10px',
    background: '#f4f4f4',
    cursor: 'pointer',
    position: 'sticky',
    top: 0,
  },
  td: {
    border: '1px solid #ccc',
    padding: '10px',
    color: '#333'
  },
};

export default EmailHistory;
