import React, { useState, useEffect } from 'react';
import SendEmail from 'components/SendEmail';
import { configurePluginEmailservice } from "services/access/plugin";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";

const MailTemplate = () => {
  const [emailServiceInfo, setEmailServiceInfo] = useState()
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configurePluginEmailservice(utils, dispatch, state);
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
      action.fetchEmailserviceItem(user_id);
  }, [user_id]);

  useEffect(() => {
    if (data?.emailservice_fetch_item) {
      setEmailServiceInfo(data?.emailservice_fetch_item)
    }
  }, [data?.emailservice_fetch_item]);

  return(
    <SendEmail emailServiceInfo={emailServiceInfo} />
  )
}

export default MailTemplate
