import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DashboardMenu = () => {
  const navigate = useNavigate();

  return (
    <nav style={styles.nav}>
      <div style={styles.container}>
        <h2 style={styles.header}><Link to="/dashboard" style={styles.link}>Dashboard</Link></h2>
        <ul style={styles.ul}>
          <li style={styles.li}><Link to="/profile" style={styles.link}>Profile</Link></li>
          <li style={styles.li}><Link to="/send-email" style={styles.link}>Send Email</Link></li>
          <li style={styles.li}><Link to="/manage-contacts" style={styles.link}>Manage Contacts</Link><span style={styles.newFeature}>New!</span></li>
          <li style={styles.li}><Link to="/email-history" style={styles.link}>Email History</Link></li>
        </ul>
        <div style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}><u>INFO</u></div>
        <ul style={styles.ul}>
          <li style={styles.li}><Link to="/faq" style={styles.link}>FAQs</Link></li>
          <li style={styles.li}><Link to="/contact" style={styles.link}>Contact</Link></li>
          <li style={styles.li}><Link to="/features" style={styles.link}>Features</Link></li>
          <li style={styles.li}><Link to="/pricing" style={styles.link}>Pricing</Link></li>
        </ul>

        <ul style={styles.ul}>
          <button style={styles.logoutBtn} onClick={()=>{localStorage.clear(); navigate('/')}}>Logout</button>
        </ul>
        {/*<ul style={styles.ul}>
          <li style={{...styles.li, ...styles.disabled}}><Link to="/create-template" style={styles.link}>Create a Template</Link></li>
          <li style={{...styles.li, ...styles.disabled}}><Link to="/available-templates" style={styles.link}>Available Templates</Link></li>
          <li style={{...styles.li, ...styles.disabled}}><Link to="/my-templates" style={styles.link}>My Templates</Link></li>
        </ul>*/}
      </div>
    </nav>
  );
}

  const styles = {
    newFeature: {
      color: '#7F7',
      fontSize: 10,
      marginLeft: 5,
    },
    soonFeature: {
      color: '#FF7',
      fontSize: 10,
      marginLeft: 5,
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#0D0C1D', // Dark background
      color: '#fff', // Light text
      height: '100vh', // Full height of the viewport
      minWidth: 200,
      overflowY: 'auto'
    },
    container: {
      margin: 10
    },
    header: {
      marginTop: '80px',
      marginBottom: 50
    },
    ul: {
      listStyleType: 'none',
      padding: 0,
      marginBottom: 40
    },
    li: {
      margin: '10px 0',
    },
    link: {
      textDecoration: 'none',
      color: '#fff', // Light text for links
    },
    logoutBtn: {
      backgroundColor: 'rgba(0,0,0,0)',
      border:'1px solid white',
      padding: 10,
      color: 'white',
      cursor: 'pointer'
    }
  };

export default DashboardMenu;
