import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';

const PdfMerger = () => {
  const [files, setFiles] = useState([]);
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const handleFilesChange = (event) => {
    setFiles(event.target.files);
  };

  const mergePdfs = async () => {
    if (files.length < 2) {
      alert('Please select at least two PDF files to merge.');
      return;
    }

    setLoading(true);
    setLoadingPercentage(0);
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await PDFDocument.load(arrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));

      // Update loading percentage
      setLoadingPercentage(Math.round(((i + 1) / files.length) * 100));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

    setMergedPdfUrl(mergedPdfUrl);
    setLoading(false);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    fontSize: '16px',
  };

  const iframeStyle = {
    marginTop: '20px',
    width: '80%',
    height: '500px',
    border: '1px solid #ccc',
  };

  const linkStyle = {
    display: 'block',
    marginTop: '10px',
    color: '#28a745',
    textDecoration: 'none',
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#28a745',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>PDF Merger</h1>
      <input type="file" accept="application/pdf" multiple onChange={handleFilesChange} style={inputStyle} />
      <button onClick={mergePdfs} style={buttonStyle} disabled={loading}>Merge PDFs</button>
      {loading && <div style={loaderStyle}>Loading... {loadingPercentage}%</div>}
      {mergedPdfUrl && (
        <div>
          <a href={mergedPdfUrl} download="merged.pdf" style={linkStyle}>Download Merged PDF</a>
          <iframe src={mergedPdfUrl} style={iframeStyle}></iframe>
        </div>
      )}
    </div>
  );
};

export default PdfMerger;
