import { useNavigate } from 'react-router-dom';
import GoogleIcon from 'assets/icon/google.png';
import {useGoogleUserLogin} from 'services/api-data/src/utils/auth'; // Adjust the import path as needed

export const NavBar = ({minimal}) => {
  const token = localStorage.getItem('tk');
  const navigate = useNavigate();
  const onLoginSuccess = () => {
    navigate('/dashboard')
  }
  const login = useGoogleUserLogin(onLoginSuccess);
  return(
    <header style={styles.header}>
      <button
        onClick={()=>navigate('/')}
        style={{ border: 'none', cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0)'}}
      >
        <h1 style={styles.logo}>TrustMailService</h1>
      </button>
      <nav style={styles.nav}>
        <a href={token ? "/features" : "#features"} style={styles.navLink}>Features</a>
        <a href={token ? "/pricing" : "#pricing"} style={styles.navLink}>Pricing</a>
        <a href={token ? "/faq" : "#faq"} style={styles.navLink}>FAQ</a>
        <a href={token ? "/contact" : "#contact"} style={styles.navLink}>Contact</a>
      </nav>
      {!token && <div style={styles.authButtons}>
        <button
          style={styles.loginButton}
           onClick={login}
        >
          <div style={{display: 'flex'}}><img src={GoogleIcon} height="15"/><div style={{marginLeft: 5}}>Login with Google</div></div>
        </button>
        <button
          style={styles.loginButton}
          onClick={() => navigate('/login')}
        >
          Login
        </button>
        <button
          style={styles.registerButton}
          onClick={() => navigate('/register')}
        >
          Register
        </button>
      </div>}
      {token && <div style={styles.authButtons}>
        <button
          style={styles.loginButton}
          onClick={() => navigate('/dashboard')}
        >
          Dashboard
        </button>
      </div>}
    </header>
  )
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#0D0C1D',
  },
  logo: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    gap: '15px',
    fontSize: '13px'
  },
  navLink: {
    textDecoration: 'none',
    color: '#fff',
  },
  authButtons: {
    display: 'flex',
    gap: '10px',
  },
  loginButton: {
    padding: '10px 20px',
    backgroundColor: '#fff',
    color: '#0D0C1D',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  registerButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}
