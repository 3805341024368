import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const PdfToImages = () => {
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const pdfToImages = async () => {
    if (!file) {
      alert('Please select a PDF file.');
      return;
    }

    setLoading(true);
    setLoadingPercentage(0);
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const typedArray = new Uint8Array(fileReader.result);
      const pdfDoc = await pdfjsLib.getDocument({ data: typedArray }).promise;
      const imagesArray = [];

      for (let i = 0; i < pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport: viewport }).promise;
        imagesArray.push(canvas.toDataURL());

        setLoadingPercentage(Math.round(((i + 1) / pdfDoc.numPages) * 100));
      }

      setImages(imagesArray);
      setLoading(false);
    };
    fileReader.readAsArrayBuffer(file);
  };

  const downloadImage = (image, index) => {
    const link = document.createElement('a');
    link.href = image;
    link.download = `page-${index + 1}.png`;
    link.click();
  };

  const downloadAllImages = async () => {
    setLoading(true);
    setLoadingPercentage(0);

    const zip = new JSZip();
    images.forEach((image, index) => {
      const imgData = image.split(',')[1];
      zip.file(`page-${index + 1}.png`, imgData, { base64: true });

      setLoadingPercentage(Math.round(((index + 1) / images.length) * 100));
    });

    const zipBlob = await zip.generateAsync({ type: 'blob' }, (metadata) => {
      setLoadingPercentage(Math.round(metadata.percent));
    });

    saveAs(zipBlob, 'images.zip');
    setLoading(false);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    margin: '10px',
    fontSize: '16px',
  };

  const imageContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '20px',
    width: '100%',
  };

  const imageWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px',
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: 'white',
  };

  const imageStyle = {
    maxWidth: '200px',
    maxHeight: '200px',
    marginBottom: '10px',
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#28a745',
  };

  const downloadAllButtonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '16px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>PDF to Images</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} style={inputStyle} />
      <button onClick={pdfToImages} style={buttonStyle} disabled={loading}>Convert to Images</button>
      {loading && <div style={loaderStyle}>Loading... {loadingPercentage}%</div>}
      {images.length > 0 && (
        <div style={{textAlign: 'center'}}>
          <button onClick={downloadAllImages} style={downloadAllButtonStyle} disabled={loading}>
            Download All Images as ZIP
          </button>
          <div style={imageContainerStyle}>
            {images.map((image, index) => (
              <div key={index} style={imageWrapperStyle}>
                <img src={image} alt={`Page ${index + 1}`} style={imageStyle} />
                <button onClick={() => downloadImage(image, index)} style={buttonStyle} disabled={loading}>
                  Download Page {index + 1}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfToImages;
