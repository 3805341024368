import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import WhatsappIcon from 'assets/icon/whatsapp.png';
import GmailIcon from 'assets/icon/gmail.png';
import FonePay from 'assets/payment/qr.png';
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";
import { configureAccountUserprofile } from "services/access/account";

const flags = {
  USD: '🇺🇸',
  NPR: '🇳🇵',
  INR: '🇮🇳',
};

export const plans = [
  { title: 'Basic', priceUSD: 2, feature: 'Up to 1,000 emails' },
  { title: 'Pro', priceUSD: 10, feature: 'Up to 10,000 emails' },
  { title: 'Enterprise', priceUSD: 50, feature: 'Up to 100,000 emails' },
];

const PricingCard = ({ title, price, feature, currency }) => {
  const navigate = useNavigate()
  return(
    <div style={styles.pricingCard}>
      <h3 style={styles.pricingTitle}>{title}</h3>
      <p style={styles.pricingPrice}>{price}</p>
      <p style={styles.pricingFeature}>{feature}</p>
      <button style={styles.pricingButton} onClick={()=>navigate(`/pricing/?title=${title}&currency=${currency}`)} >Choose Plan</button>
    </div>
)};

const Pricing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(utils, dispatch, state);
  const user_id = localStorage.getItem("user_id");
  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  const [paymentMethod, setPaymentMethod] = useState('FonePay')
  const [numberOfMonths, setNumberOfMonths] = useState(1)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title_param = queryParams.get('title');
  const currency_param = queryParams.get('currency');
  const plan = plans.find(plan => plan.title === title_param);

  const [currency, setCurrency] = useState(currency_param || 'USD');
  const [conversionRates, setConversionRates] = useState({ NPR: 120, INR: 80, USD: 1 }); // default conversion rates

  useEffect(() => {
    const fetchConversionRates = async () => {
      try {
        const response = await fetch(
          `https://v6.exchangerate-api.com/v6/24e4616b9718bb2cb513a9c1/latest/USD`
        );
        const data = await response.json();
        setConversionRates({
          USD: data.conversion_rates.USD,
          NPR: data.conversion_rates.NPR,
          INR: data.conversion_rates.INR,
        });
      } catch (error) {
        console.error('Error fetching conversion rates:', error);
      }
    };

    fetchConversionRates();
  }, []);

  const formatPrice = (priceUSD) => {
    switch (currency) {
      case 'NPR':
        return `रू${(priceUSD * conversionRates.NPR).toFixed(0)}/month`;
      case 'INR':
        return `₹${(priceUSD * conversionRates.INR).toFixed(0)}/month`;
      default:
        return `$${priceUSD}/month`;
    }
  };
  const formatTotalPrice = (priceUSD) => {
    switch (currency) {
      case 'NPR':
        return `रू${(numberOfMonths * priceUSD * conversionRates.NPR).toFixed(0)}`;
      case 'INR':
        return `₹${(numberOfMonths * priceUSD * conversionRates.INR).toFixed(0)}`;
      default:
        return `$${numberOfMonths * priceUSD}`;
    }
  };

  if (title_param && currency_param) {
    const whatsappNumber = '+977-9849826321';
    const associated_account_email = data?.userprofile_fetch_item?.email;
    const whatsappText = `[Email Subscription for - ${associated_account_email}] Payment detail attachment for ${title_param} plan through ${paymentMethod} for ${numberOfMonths} months at total ${formatTotalPrice(plan.priceUSD)}.`;
    const whatsappUrl = `https://web.whatsapp.com/send?phone=${whatsappNumber}&text=${whatsappText}`;
    const gmailBody =
`Hi there,

Please check the attachment for the following payment details.
- Plan: ${title_param} plan
- Duration: ${numberOfMonths} months
- Method: ${paymentMethod}
- Amount: ${formatTotalPrice(plan.priceUSD)} [${currency}]
- Associated account Email: ${associated_account_email}
`;
    const gmailAddress = `info@corpolatech.com`;
    const gmailSubject = `[Email Subscription] Payment detail for ${title_param} Plan through ${paymentMethod}`;
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(gmailAddress)}&su=${encodeURIComponent(gmailSubject)}&body=${encodeURIComponent(gmailBody)}`

    return(
      <div style={styles.container}>
        <h1 style={styles.title}>{title_param} Plan</h1>
        <p style={styles.price}>Price: {formatPrice(plan.priceUSD)}</p>
        <p style={styles.feature}>Feature: {plan.feature}</p>
        <br/>
        <p style={styles.feature}>Purchase plan for
          <select name="cars" id="cars" style={{margin: 5, padding: 5}} value={numberOfMonths} onChange={(e)=>setNumberOfMonths(e.target.value)}>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={6}>6</option>
            <option value={9}>9</option>
            <option value={12}>12</option>
          </select>
          months
        </p>
        <p>
          <b>Total: {formatTotalPrice(plan.priceUSD)}</b>
        </p>
        <div>Make Payment via:</div>
        <div style={{display: 'flex', maxWidth:400, margin: 'auto'}}>
          <button style={{flex: 1, margin: 5, backgroundColor: paymentMethod === 'FonePay' ? '#000':'#007BFF', border: 'none', color: 'white', padding:5, cursor:'pointer'}} onClick={()=>setPaymentMethod('FonePay')}>FonePay</button>
          <button style={{flex: 1, margin: 5, backgroundColor: paymentMethod === 'Bank Account' ? '#000':'#007BFF', border: 'none', color: 'white', padding:5, cursor:'pointer'}} onClick={()=>setPaymentMethod('Bank Account')}>Bank Account</button>
          <button style={{flex: 1, margin: 5, backgroundColor: paymentMethod === 'Other' ? '#000':'#007BFF', border: 'none', color: 'white', padding:5, cursor:'pointer'}} onClick={()=>setPaymentMethod('Other')}>Other</button>
        </div>
        <div style={{width:400, margin: 'auto', display: 'flex'}}>
          {paymentMethod === 'FonePay' && <div>
            <img src={FonePay} height={250}/>
          </div>}
          {paymentMethod === 'Bank Account' && <div style={{flex: 3, fontSize: 13, padding: 10}}>
            <br/>
            <div><u>Bank account details</u></div>
            <br/>
            <div><b>Bank</b>: Laxmi Sunrise Bank</div>
            <div><b>A/C Title</b>: CORPOLA TECH PVT LTD</div>
            <div><b>A/C Number</b>: 10011001136</div>
            <div><b>Branch</b>: HANDIGAUN</div>
            <div><b>Swift code</b>: LXBLNPKA</div>
          </div>}
          {paymentMethod === 'Other' && <div style={{flex: 2, fontSize: 13}}>
          <br/>
          <br/>
            Send us an email at info@corpolatech.com about the details of the payment method you want to use and we will get back to you for using other mode of payment.
          </div>}
          {paymentMethod && paymentMethod !== 'Other' && <div style={{flex: 2}}>
            <br/>
            <div><i>Send the payment detail (statement) through one of the following contact:</i></div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
              <div style={{ textAlign: 'center'}}>
                <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" style={{display: 'flex', margin: 10, textDecoration: 'none', border: '1px solid #777', margin: 1, borderRadius: 5, backgroundColor: '#efefef', padding: 10}}>
                  <img src={WhatsappIcon} height={15} />
                  <span style={{marginLeft: 5, fontSize: 12, color: '#333'}}>WhatsApp</span>
                </a>
              </div>
              <div style={{}}>
                <a style={{display: 'flex', margin: 10, textDecoration: 'none', border: '1px solid #777', margin: 1, borderRadius: 5, backgroundColor: '#efefef', padding: 10}} target="_blank" href={gmailUrl}>
                  <img src={GmailIcon} height={15} />
                  <span style={{marginLeft: 5, fontSize: 12, color: '#333'}}>Gmail</span>
                </a>
              </div>
              <div>

              </div>
            </div>
          </div>}

        </div>
        {!paymentMethod && <div>
          <small><i>Please select a payment method</i></small>
          </div>}
        <br/>

      </div>
    )
  }

  return (
    <section id="pricing" style={styles.pricingSection}>
      <h2 style={styles.sectionTitle}>Pricing</h2>
      <div style={styles.currencySelector}>
        {Object.keys(flags).map((cur) => (
          <button
            key={cur}
            style={{
              ...styles.currencyButton,
              backgroundColor: currency === cur ? '#007bff' : '#fff',
              color: currency === cur ? '#fff' : '#007bff',
            }}
            onClick={() => setCurrency(cur)}
          >
            {flags[cur]} {cur}
          </button>
        ))}
      </div>
      <div style={styles.pricingContainer}>
        {plans.map((plan, index) => (
          <PricingCard
            key={index}
            title={plan.title}
            price={formatPrice(plan.priceUSD)}
            feature={plan.feature}
            currency={currency}
          />
        ))}
      </div>
    </section>
  );
};

const styles = {
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    color: '#343a40',
  },
  title: {
    fontSize: '32px',
    marginBottom: '20px',
  },
  price: {
    fontSize: '24px',
    margin: '10px 0',
  },
  feature: {
    fontSize: '18px',
    margin: '10px 0',
  },
  sectionTitle: {
    fontSize: '28px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  pricingSection: {
    padding: '50px 20px',
  },
  currencySelector: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  currencyButton: {
    fontSize: '18px',
    padding: '10px 20px',
    border: '1px solid #007bff',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#007bff',
    display: 'flex',
    alignItems: 'center',
  },
  pricingContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  pricingCard: {
    flex: '1 1 30%',
    border: '1px solid #dee2e6',
    backgroundColor: '#ffffffaa',
    color: '#333',
    margin: 5,
    borderRadius: '5px',
    textAlign: 'center',
    padding: '10px',
    paddingBottom: '30px',
    marginBottom: '20px',
  },
  pricingTitle: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#0D0C1D'
  },
  pricingPrice: {
    fontSize: '20px',
    marginBottom: '10px',
  },
  pricingFeature: {
    marginBottom: '20px',
  },
  pricingButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Pricing;
