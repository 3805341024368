import { API_BASE_URL } from "App";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useGoogleLogin } from '@react-oauth/google';

export async function createUser(data) {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  });
  try {
    const res = await axiosInstance.post(`/account/register/`, data);
    toast.success("You have successfully registered!");
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error(`Registration failed. ${error.response.data.error}`);
  }
};

export async function createUserToken(data) {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  });
  try {
    const res = await axiosInstance.post("/user-token/", data);
    toast.success("You have successfully logged in!");
    const token = res.data;
    setUserData(token);
    return res.data;
  } catch (error) {
    toast.error("Login failed. Please check your credentials and try again.");
  }
}

export const useGoogleUserLogin = (onSuccessCallback) => {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  });

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const data = { access_token: tokenResponse.access_token };
        const res = await axiosInstance.post('/account/google-login/', data);
        toast.success('You have successfully logged in!');
        const token = res.data;
        setUserData(token); // Assuming setUserData is a function to set user data in your app
        if (onSuccessCallback) {
          onSuccessCallback(token);
        }
        return res.data;
      } catch (error) {
        toast.error('Login failed. Please check your credentials and try again.');
      }
    },
    onError: (error) => {
      toast.error('Login failed. Try again.');
    }
  });

  return login;
};

export const setUserData = (value) => {
  const accessToken = value.access;
  const decoded = jwtDecode(accessToken);
  const user_id = decoded.user_id;
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("tk", JSON.stringify(value));
    localStorage.setItem("user_id", user_id);
  }
};
