import React, { useState } from 'react';
import { getDocument } from 'pdfjs-dist';

const PdfTextExtractor = () => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const extractTextFromPdf = async () => {
    if (!file) {
      alert('Please select a PDF file.');
      return;
    }

    setLoading(true);
    setText('');
    setLoadingPercentage(0);

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const typedArray = new Uint8Array(fileReader.result);
      const pdf = await getDocument({ data: typedArray }).promise;
      const numPages = pdf.numPages;
      let extractedText = '';

      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        extractedText += pageText + '\n\n';

        setLoadingPercentage(Math.round((i / numPages) * 100));
      }

      setText(extractedText);
      setLoading(false);
    };
    fileReader.readAsArrayBuffer(file);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Text copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const downloadTextFile = () => {
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'extracted-text.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    fontSize: '16px',
    margin: '5px',
  };

  const textContainerStyle = {
    marginTop: '20px',
    width: '80%',
    maxHeight: '500px',
    overflowY: 'scroll',
    padding: '10px',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    borderRadius: '5px',
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#007bff',
  };

  const buttonContainerStyle = {
    marginTop: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>PDF Text Extractor</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} style={inputStyle} />
      <button onClick={extractTextFromPdf} style={buttonStyle} disabled={loading}>Extract Text</button>
      {loading && <div style={loaderStyle}>Loading... {loadingPercentage}%</div>}
      {text && (
        <div style={buttonContainerStyle}>
          <button onClick={copyToClipboard} style={buttonStyle}>Copy to Clipboard</button>
          <button onClick={downloadTextFile} style={buttonStyle}>Download as TXT</button>
        </div>
      )}
      {text && (
        <div style={textContainerStyle}>
          <pre>{text}</pre>
        </div>
      )}
    </div>
  );
};

export default PdfTextExtractor;
