import React, { useState, Suspense, lazy, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
const LayoutSelector = ({selectedCategory, selectedSubcategory, children}) => {
    return (
        <div>
            {selectedCategory && selectedSubcategory && (
                <Suspense fallback={<div>Loading...</div>}>
                  {children}
                </Suspense>
            )}
        </div>
    );
};

export default LayoutSelector;
