import React, { useState } from 'react';

const Modal = ({isOpen, setIsOpen, children}) => {

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleBackdropClick = () => {
    closeModal();
  };

  return (
    <div>
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            zIndex: '1000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          }}
        >
          <div style={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                top: '-30px',
                right: '-10px',
                fontSize: '24px',
                cursor: 'pointer'
              }}
              onClick={closeModal}
            >
              &times;
            </span>
            {children}
          </div>
        </div>
      )}

      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
            backdropFilter: 'blur(5px)', // Blur effect for modern browsers
            zIndex: '999'
          }}
          onClick={handleBackdropClick}
        ></div>
      )}
    </div>
  );
};

export default Modal;
