import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Navigate } from 'react-router-dom';
import CaptchaVerification from "components/captcha";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";
import { configureAccountUserprofile } from "services/access/account";
import { createUser } from "services/api-data/src/utils/auth";

const RegisterForm = ({ formData, setFormData, captchaVerify, setCaptchaVerify, loading, handleRegister }) => {
  const navigate = useNavigate()
  return (
    <small>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Register a new account</h2>
      <div style={{display: 'flex'}}>
        <div style={{ marginBottom: '20px', marginRight: 10 }}>
          <label style={{ fontSize: 12, display: 'block', marginBottom: '8px', color: '#555' }}>First Name</label>
          <input
            type="text"
            placeholder="First Name"
            value={formData?.first_name}
            onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
            required
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label style={{ fontSize: 12, display: 'block', marginBottom: '8px', color: '#555' }}>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            value={formData?.last_name}
            onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
            required
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
          />
        </div>
      </div>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <div style={{flex: 1}}>
          <label style={{ fontSize: 12, display: 'block', marginBottom: '8px', color: '#555' }}>Email</label>
          <input
            type="text"
            placeholder="Enter email"
            value={formData?.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginBottom: '20px', marginRight: 10 }}>
          <label style={{ fontSize: 12, display: 'block', marginBottom: '8px', color: '#555' }}>Password</label>
          <input
            type="password"
            placeholder="Enter password"
            value={formData?.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            required
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label style={{ fontSize: 12, display: 'block', marginBottom: '8px', color: '#555' }}>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm password"
            value={formData?.confirmPassword}
            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
            required
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '14px' }}
          />
        </div>
      </div>
      <CaptchaVerification {...{ captchaVerify, setCaptchaVerify }} />
      <button
        type="submit"
        disabled={!captchaVerify || loading}
        onClick={handleRegister}
        style={{ width: '100%', padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: (!captchaVerify || loading) ? '#ccc' : '#007bff', color: '#fff', fontSize: '14px', cursor: (!captchaVerify || loading) ? 'not-allowed' : 'pointer' }}
      >
        Register
      </button>
      <div style={{marginTop: 10}}>Already have an account? <button style={{backgroundColor:'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', color: '#007BFF'}} onClick={()=>navigate('/login')}>Login</button></div>
    </small>
  )
}

function Register() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );
  const [loading, setLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [captchaVerify, setCaptchaVerify] = useState(false);

  const handleRegister = async () => {
    setLoading(true);
    const response = await createUser(formData);
    if (response?.message) {
        navigate('/login')
    }
    setLoading(false);
  };

  if (localStorage.getItem('tk')) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(135deg, #e0e7ff 0%, #d5d9ff 20%, #c5c7ff 40%, #b4b4ff 60%, #a3a1ff 80%, #928eff 100%)', overflowY: 'auto' }}>
      <div style={{ margin: 30, padding: '40px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
        <RegisterForm {...{ formData, setFormData, captchaVerify, setCaptchaVerify, loading, handleRegister }} />
      </div>
    </div>
  );
}

export default Register;
