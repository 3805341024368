import axios from '../utils/axios';

import {fetchAccountUserprofileListStart, fetchAccountUserprofileListSuccess, fetchAccountUserprofileListFailure} from '../features/account'
import {fetchAccountUserprofileItemStart, fetchAccountUserprofileItemSuccess, fetchAccountUserprofileItemFailure} from '../features/account'
import {createAccountUserprofileItemStart, createAccountUserprofileItemSuccess, createAccountUserprofileItemFailure} from '../features/account'
import {updateAccountUserprofileItemStart, updateAccountUserprofileItemSuccess, updateAccountUserprofileItemFailure} from '../features/account'
import {deleteAccountUserprofileItemStart, deleteAccountUserprofileItemSuccess, deleteAccountUserprofileItemFailure} from '../features/account'

import {fetchAccountUsermessagedispatchListStart, fetchAccountUsermessagedispatchListSuccess, fetchAccountUsermessagedispatchListFailure} from '../features/account'
import {fetchAccountUsermessagedispatchItemStart, fetchAccountUsermessagedispatchItemSuccess, fetchAccountUsermessagedispatchItemFailure} from '../features/account'
import {createAccountUsermessagedispatchItemStart, createAccountUsermessagedispatchItemSuccess, createAccountUsermessagedispatchItemFailure} from '../features/account'
import {updateAccountUsermessagedispatchItemStart, updateAccountUsermessagedispatchItemSuccess, updateAccountUsermessagedispatchItemFailure} from '../features/account'
import {deleteAccountUsermessagedispatchItemStart, deleteAccountUsermessagedispatchItemSuccess, deleteAccountUsermessagedispatchItemFailure} from '../features/account'


// USERPROFILE ACTIONS
export const fetchAccountUserprofileList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUserprofileListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/userprofile/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountUserprofileListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUserprofileListFailure({key, error: error.message}));
    }
}
export const fetchAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const createAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/userprofile/`, info.payload, { headers: info.headers });
        dispatch(createAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const updateAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const deleteAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountUserprofileItemFailure({key, error: error.message}));
    }
}


// USERMESSAGEDISPATCH ACTIONS
export const fetchAccountUsermessagedispatchList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUsermessagedispatchListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/usermessagedispatch/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountUsermessagedispatchListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUsermessagedispatchListFailure({key, error: error.message}));
    }
}
export const fetchAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/usermessagedispatch/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}
export const createAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/usermessagedispatch/`, info.payload, { headers: info.headers });
        dispatch(createAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}
export const updateAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/usermessagedispatch/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}
export const deleteAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/usermessagedispatch/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}




