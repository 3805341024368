const PAGE_LIMIT = 8
const BIG_LIMIT = 1000

export const configurePluginEmailplugin = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    emailplugin_fetch_list: state['plugin']?.emailplugin_list,
    emailplugin_fetch_item: state['plugin']?.emailplugin_item,
    emailplugin_create_item: state['plugin']?.emailplugin_item,
    emailplugin_update_item: state['plugin']?.emailplugin_item,
    emailplugin_delete_item: state['plugin']?.deleted_emailplugin,
    emailplugin_loading: state['plugin']?.loading,
  },
  action: {
    fetchEmailpluginList: () => {
      const emailplugin_fetch_list_request = { dispatch, request: ['plugin', 'emailplugin', 'list'], request_info: {}, auth: false}
      utils.action(emailplugin_fetch_list_request)
    },
    fetchEmailpluginItem: (id) => {
      const emailplugin_fetch_item_request = { dispatch, request: ['plugin', 'emailplugin', 'read'], request_info: {id}, auth: false}
      utils.action(emailplugin_fetch_item_request)
    },
    createEmailpluginItem: (payload) => {
      const emailplugin_create_item_request = { dispatch, request: ['plugin', 'emailplugin', 'create'], request_info: payload, auth: false}
      utils.action(emailplugin_create_item_request)
    },
    updateEmailpluginItem: (id, payload) => {
      const emailplugin_update_item_request = { dispatch, request: ['plugin', 'emailplugin', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(emailplugin_update_item_request)
    },
    deleteEmailpluginItem: (id) => {
      const emailplugin_delete_item_request = { dispatch, request: ['plugin', 'emailplugin', 'delete'], request_info: {id}, auth: false}
      utils.action(emailplugin_delete_item_request)
    },
  }
})

export const configurePluginEmailservice = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    emailservice_fetch_list: state['plugin']?.emailservice_list,
    emailservice_fetch_item: state['plugin']?.emailservice_item,
    emailservice_create_item: state['plugin']?.emailservice_item,
    emailservice_update_item: state['plugin']?.emailservice_item,
    emailservice_delete_item: state['plugin']?.deleted_emailservice,
    emailservice_loading: state['plugin']?.loading,
  },
  action: {
    fetchEmailserviceList: () => {
      const emailservice_fetch_list_request = { dispatch, request: ['plugin', 'emailservice', 'list'], request_info: {}, auth: false}
      utils.action(emailservice_fetch_list_request)
    },
    fetchEmailserviceItem: (user_id) => {
      const emailservice_fetch_item_request = { dispatch, request: ['plugin', 'emailservice', 'read'], request_info: {user_id, lookup_field: 'user_id'}, auth: true}
      utils.action(emailservice_fetch_item_request)
    },
    createEmailserviceItem: (payload) => {
      const emailservice_create_item_request = { dispatch, request: ['plugin', 'emailservice', 'create'], request_info: payload, auth: false}
      utils.action(emailservice_create_item_request)
    },
    updateEmailserviceItem: (id, payload) => {
      const emailservice_update_item_request = { dispatch, request: ['plugin', 'emailservice', 'update'], request_info: {id, ...payload}, auth: true}
      utils.action(emailservice_update_item_request)
    },
    deleteEmailserviceItem: (id) => {
      const emailservice_delete_item_request = { dispatch, request: ['plugin', 'emailservice', 'delete'], request_info: {id}, auth: false}
      utils.action(emailservice_delete_item_request)
    },
  }
})

export const configurePluginEmailgroup = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    emailgroup_fetch_list: state['plugin']?.emailgroup_list,
    emailgroup_fetch_item: state['plugin']?.emailgroup_item,
    emailgroup_create_item: state['plugin']?.emailgroup_item,
    emailgroup_update_item: state['plugin']?.emailgroup_item,
    emailgroup_delete_item: state['plugin']?.deleted_emailgroup,
    emailgroup_loading: state['plugin']?.loading,
  },
  action: {
    fetchEmailgroupList: () => {
      const emailgroup_fetch_list_request = { dispatch, request: ['plugin', 'emailgroup', 'list'], request_info: {}, auth: true}
      utils.action(emailgroup_fetch_list_request)
    },
    fetchEmailgroupItem: (id) => {
      const emailgroup_fetch_item_request = { dispatch, request: ['plugin', 'emailgroup', 'read'], request_info: {id}, auth: false}
      utils.action(emailgroup_fetch_item_request)
    },
    createEmailgroupItem: (payload) => {
      const emailgroup_create_item_request = { dispatch, request: ['plugin', 'emailgroup', 'create'], request_info: payload, auth: true}
      utils.action(emailgroup_create_item_request)
    },
    updateEmailgroupItem: (id, payload) => {
      const emailgroup_update_item_request = { dispatch, request: ['plugin', 'emailgroup', 'update'], request_info: {id, ...payload}, auth: true}
      utils.action(emailgroup_update_item_request)
    },
    deleteEmailgroupItem: (id) => {
      const emailgroup_delete_item_request = { dispatch, request: ['plugin', 'emailgroup', 'delete'], request_info: {id}, auth: true}
      utils.action(emailgroup_delete_item_request)
    },
  }
})

export const configurePluginEmail = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    email_fetch_list: state['plugin']?.email_list,
    email_fetch_item: state['plugin']?.email_item,
    email_create_item: state['plugin']?.email_item,
    email_update_item: state['plugin']?.email_item,
    email_delete_item: state['plugin']?.deleted_email,
    email_loading: state['plugin']?.loading,
  },
  action: {
    fetchEmailList: () => {
      const email_fetch_list_request = { dispatch, request: ['plugin', 'email', 'list'], request_info: {}, auth: true}
      utils.action(email_fetch_list_request)
    },
    fetchEmailItem: (id) => {
      const email_fetch_item_request = { dispatch, request: ['plugin', 'email', 'read'], request_info: {id}, auth: false}
      utils.action(email_fetch_item_request)
    },
    createEmailItem: (payload) => {
      const email_create_item_request = { dispatch, request: ['plugin', 'email', 'create'], request_info: payload, auth: true}
      utils.action(email_create_item_request)
    },
    updateEmailItem: (id, payload) => {
      const email_update_item_request = { dispatch, request: ['plugin', 'email', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(email_update_item_request)
    },
    deleteEmailItem: (id) => {
      const email_delete_item_request = { dispatch, request: ['plugin', 'email', 'delete'], request_info: {id}, auth: true}
      utils.action(email_delete_item_request)
    },
  }
})

export const configurePluginEmailhistory = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    emailhistory_fetch_list: state['plugin']?.emailhistory_list,
    emailhistory_fetch_item: state['plugin']?.emailhistory_item,
    emailhistory_create_item: state['plugin']?.emailhistory_item,
    emailhistory_update_item: state['plugin']?.emailhistory_item,
    emailhistory_delete_item: state['plugin']?.deleted_emailhistory,
    emailhistory_loading: state['plugin']?.loading,
  },
  action: {
    fetchEmailhistoryList: () => {
      const emailhistory_fetch_list_request = { dispatch, request: ['plugin', 'emailhistory', 'list'], request_info: {}, auth: true}
      utils.action(emailhistory_fetch_list_request)
    },
    fetchEmailhistoryItem: (id) => {
      const emailhistory_fetch_item_request = { dispatch, request: ['plugin', 'emailhistory', 'read'], request_info: {id}, auth: false}
      utils.action(emailhistory_fetch_item_request)
    },
    createEmailhistoryItem: (payload) => {
      const emailhistory_create_item_request = { dispatch, request: ['plugin', 'emailhistory', 'create'], request_info: payload, auth: false}
      utils.action(emailhistory_create_item_request)
    },
    updateEmailhistoryItem: (id, payload) => {
      const emailhistory_update_item_request = { dispatch, request: ['plugin', 'emailhistory', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(emailhistory_update_item_request)
    },
    deleteEmailhistoryItem: (id) => {
      const emailhistory_delete_item_request = { dispatch, request: ['plugin', 'emailhistory', 'delete'], request_info: {id}, auth: false}
      utils.action(emailhistory_delete_item_request)
    },
  }
})

export const configurePluginEmailsubscription = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    emailsubscription_fetch_list: state['plugin']?.emailsubscription_list,
    emailsubscription_fetch_item: state['plugin']?.emailsubscription_item,
    emailsubscription_create_item: state['plugin']?.emailsubscription_item,
    emailsubscription_update_item: state['plugin']?.emailsubscription_item,
    emailsubscription_delete_item: state['plugin']?.deleted_emailsubscription,
    emailsubscription_loading: state['plugin']?.loading,
  },
  action: {
    fetchEmailsubscriptionList: () => {
      const emailsubscription_fetch_list_request = { dispatch, request: ['plugin', 'emailsubscription', 'list'], request_info: {}, auth: false}
      utils.action(emailsubscription_fetch_list_request)
    },
    fetchEmailsubscriptionItem: (id) => {
      const emailsubscription_fetch_item_request = { dispatch, request: ['plugin', 'emailsubscription', 'read'], request_info: {id}, auth: true}
      utils.action(emailsubscription_fetch_item_request)
    },
    createEmailsubscriptionItem: (payload) => {
      const emailsubscription_create_item_request = { dispatch, request: ['plugin', 'emailsubscription', 'create'], request_info: payload, auth: false}
      utils.action(emailsubscription_create_item_request)
    },
    updateEmailsubscriptionItem: (id, payload) => {
      const emailsubscription_update_item_request = { dispatch, request: ['plugin', 'emailsubscription', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(emailsubscription_update_item_request)
    },
    deleteEmailsubscriptionItem: (id) => {
      const emailsubscription_delete_item_request = { dispatch, request: ['plugin', 'emailsubscription', 'delete'], request_info: {id}, auth: false}
      utils.action(emailsubscription_delete_item_request)
    },
  }
})
