import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';

const PdfSplitter = () => {
  const [file, setFile] = useState(null);
  const [splitPdfs, setSplitPdfs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const splitPdf = async () => {
    if (!file) {
      alert('Please select a PDF file.');
      return;
    }

    setLoading(true);
    setLoadingPercentage(0);
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const typedArray = new Uint8Array(fileReader.result);
      const pdfDoc = await PDFDocument.load(typedArray);
      const numPages = pdfDoc.getPageCount();
      const splitFiles = [];

      for (let i = 0; i < numPages; i++) {
        const newPdfDoc = await PDFDocument.create();
        const [page] = await newPdfDoc.copyPages(pdfDoc, [i]);
        newPdfDoc.addPage(page);
        const pdfBytes = await newPdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        splitFiles.push(pdfUrl);

        setLoadingPercentage(Math.round(((i + 1) / numPages) * 100));
      }

      setSplitPdfs(splitFiles);
      setLoading(false);
    };
    fileReader.readAsArrayBuffer(file);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    fontSize: '16px',
  };

  const linkStyle = {
    display: 'block',
    marginTop: '10px',
    color: '#007bff',
    textDecoration: 'none',
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#007bff',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>PDF Splitter</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} style={inputStyle} />
      <button onClick={splitPdf} style={buttonStyle} disabled={loading}>Split PDF</button>
      {loading && <div style={loaderStyle}>Loading... {loadingPercentage}%</div>}
      {splitPdfs.length > 0 && (
        <div>
          {splitPdfs.map((url, index) => (
            <a key={index} href={url} download={`split-page-${index + 1}.pdf`} style={linkStyle}>
              Download Page {index + 1}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default PdfSplitter;
