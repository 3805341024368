import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';

const ImagesToPdf = () => {
  const [files, setFiles] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const handleFilesChange = (event) => {
    setFiles(event.target.files);
  };

  const imagesToPdf = async () => {
    if (files.length === 0) {
      alert('Please select at least one image file.');
      return;
    }

    setLoading(true);
    setLoadingPercentage(0);
    const pdfDoc = await PDFDocument.create();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const arrayBuffer = await file.arrayBuffer();
      const imageType = file.type.split('/')[1];
      let embeddedImage;

      if (imageType === 'jpeg' || imageType === 'jpg') {
        embeddedImage = await pdfDoc.embedJpg(arrayBuffer);
      } else if (imageType === 'png') {
        embeddedImage = await pdfDoc.embedPng(arrayBuffer);
      } else {
        alert('Unsupported image type. Please use JPG or PNG.');
        setLoading(false);
        return;
      }

      const page = pdfDoc.addPage([embeddedImage.width, embeddedImage.height]);
      page.drawImage(embeddedImage, {
        x: 0,
        y: 0,
        width: embeddedImage.width,
        height: embeddedImage.height,
      });

      setLoadingPercentage(Math.round(((i + 1) / files.length) * 100));
    }

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setPdfUrl(pdfUrl);
    setLoading(false);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    fontSize: '16px',
  };

  const iframeStyle = {
    marginTop: '20px',
    width: '80%',
    height: '500px',
    border: '1px solid #ccc',
  };

  const linkStyle = {
    display: 'block',
    marginTop: '10px',
    color: '#28a745',
    textDecoration: 'none',
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#28a745',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Images to PDF</h1>
      <input type="file" accept="image/jpeg, image/png" multiple onChange={handleFilesChange} style={inputStyle} />
      <button onClick={imagesToPdf} style={buttonStyle} disabled={loading}>Convert to PDF</button>
      {loading && <div style={loaderStyle}>Loading... {loadingPercentage}%</div>}
      {pdfUrl && (
        <div>
          <a href={pdfUrl} download="images.pdf" style={linkStyle}>Download PDF</a>
          <iframe src={pdfUrl} style={iframeStyle}></iframe>
        </div>
      )}
    </div>
  );
};

export default ImagesToPdf;
