import axios from '../utils/axios';

import {fetchPluginEmailpluginListStart, fetchPluginEmailpluginListSuccess, fetchPluginEmailpluginListFailure} from '../features/plugin'
import {fetchPluginEmailpluginItemStart, fetchPluginEmailpluginItemSuccess, fetchPluginEmailpluginItemFailure} from '../features/plugin'
import {createPluginEmailpluginItemStart, createPluginEmailpluginItemSuccess, createPluginEmailpluginItemFailure} from '../features/plugin'
import {updatePluginEmailpluginItemStart, updatePluginEmailpluginItemSuccess, updatePluginEmailpluginItemFailure} from '../features/plugin'
import {deletePluginEmailpluginItemStart, deletePluginEmailpluginItemSuccess, deletePluginEmailpluginItemFailure} from '../features/plugin'

import {fetchPluginEmailserviceListStart, fetchPluginEmailserviceListSuccess, fetchPluginEmailserviceListFailure} from '../features/plugin'
import {fetchPluginEmailserviceItemStart, fetchPluginEmailserviceItemSuccess, fetchPluginEmailserviceItemFailure} from '../features/plugin'
import {createPluginEmailserviceItemStart, createPluginEmailserviceItemSuccess, createPluginEmailserviceItemFailure} from '../features/plugin'
import {updatePluginEmailserviceItemStart, updatePluginEmailserviceItemSuccess, updatePluginEmailserviceItemFailure} from '../features/plugin'
import {deletePluginEmailserviceItemStart, deletePluginEmailserviceItemSuccess, deletePluginEmailserviceItemFailure} from '../features/plugin'

import {fetchPluginEmailhistoryListStart, fetchPluginEmailhistoryListSuccess, fetchPluginEmailhistoryListFailure} from '../features/plugin'
import {fetchPluginEmailhistoryItemStart, fetchPluginEmailhistoryItemSuccess, fetchPluginEmailhistoryItemFailure} from '../features/plugin'
import {createPluginEmailhistoryItemStart, createPluginEmailhistoryItemSuccess, createPluginEmailhistoryItemFailure} from '../features/plugin'
import {updatePluginEmailhistoryItemStart, updatePluginEmailhistoryItemSuccess, updatePluginEmailhistoryItemFailure} from '../features/plugin'
import {deletePluginEmailhistoryItemStart, deletePluginEmailhistoryItemSuccess, deletePluginEmailhistoryItemFailure} from '../features/plugin'

import {fetchPluginEmailsubscriptionListStart, fetchPluginEmailsubscriptionListSuccess, fetchPluginEmailsubscriptionListFailure} from '../features/plugin'
import {fetchPluginEmailsubscriptionItemStart, fetchPluginEmailsubscriptionItemSuccess, fetchPluginEmailsubscriptionItemFailure} from '../features/plugin'
import {createPluginEmailsubscriptionItemStart, createPluginEmailsubscriptionItemSuccess, createPluginEmailsubscriptionItemFailure} from '../features/plugin'
import {updatePluginEmailsubscriptionItemStart, updatePluginEmailsubscriptionItemSuccess, updatePluginEmailsubscriptionItemFailure} from '../features/plugin'
import {deletePluginEmailsubscriptionItemStart, deletePluginEmailsubscriptionItemSuccess, deletePluginEmailsubscriptionItemFailure} from '../features/plugin'

import {fetchPluginEmailgroupListStart, fetchPluginEmailgroupListSuccess, fetchPluginEmailgroupListFailure} from '../features/plugin'
import {fetchPluginEmailgroupItemStart, fetchPluginEmailgroupItemSuccess, fetchPluginEmailgroupItemFailure} from '../features/plugin'
import {createPluginEmailgroupItemStart, createPluginEmailgroupItemSuccess, createPluginEmailgroupItemFailure} from '../features/plugin'
import {updatePluginEmailgroupItemStart, updatePluginEmailgroupItemSuccess, updatePluginEmailgroupItemFailure} from '../features/plugin'
import {deletePluginEmailgroupItemStart, deletePluginEmailgroupItemSuccess, deletePluginEmailgroupItemFailure} from '../features/plugin'

import {fetchPluginEmailListStart, fetchPluginEmailListSuccess, fetchPluginEmailListFailure} from '../features/plugin'
import {fetchPluginEmailItemStart, fetchPluginEmailItemSuccess, fetchPluginEmailItemFailure} from '../features/plugin'
import {createPluginEmailItemStart, createPluginEmailItemSuccess, createPluginEmailItemFailure} from '../features/plugin'
import {updatePluginEmailItemStart, updatePluginEmailItemSuccess, updatePluginEmailItemFailure} from '../features/plugin'
import {deletePluginEmailItemStart, deletePluginEmailItemSuccess, deletePluginEmailItemFailure} from '../features/plugin'


// EMAILPLUGIN ACTIONS
export const fetchPluginEmailpluginList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailpluginListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailplugin/`, { params: info.params, headers: info.headers });
        dispatch(fetchPluginEmailpluginListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailpluginListFailure({key, error: error.message}));
    }
}
export const fetchPluginEmailpluginItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailpluginItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailplugin/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPluginEmailpluginItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailpluginItemFailure({key, error: error.message}));
    }
}
export const createPluginEmailpluginItem = async (dispatch, info, key) => {
    try{
        dispatch(createPluginEmailpluginItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/plugin/emailplugin/`, info.payload, { headers: info.headers });
        dispatch(createPluginEmailpluginItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPluginEmailpluginItemFailure({key, error: error.message}));
    }
}
export const updatePluginEmailpluginItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePluginEmailpluginItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/plugin/emailplugin/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePluginEmailpluginItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePluginEmailpluginItemFailure({key, error: error.message}));
    }
}
export const deletePluginEmailpluginItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePluginEmailpluginItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/plugin/emailplugin/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePluginEmailpluginItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePluginEmailpluginItemFailure({key, error: error.message}));
    }
}


// EMAILSERVICE ACTIONS
export const fetchPluginEmailserviceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailserviceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailservice/`, { params: info.params, headers: info.headers });
        dispatch(fetchPluginEmailserviceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailserviceListFailure({key, error: error.message}));
    }
}
export const fetchPluginEmailserviceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailserviceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailservice/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPluginEmailserviceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailserviceItemFailure({key, error: error.message}));
    }
}
export const createPluginEmailserviceItem = async (dispatch, info, key) => {
    try{
        dispatch(createPluginEmailserviceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/plugin/emailservice/`, info.payload, { headers: info.headers });
        dispatch(createPluginEmailserviceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPluginEmailserviceItemFailure({key, error: error.message}));
    }
}
export const updatePluginEmailserviceItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePluginEmailserviceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/plugin/emailservice/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePluginEmailserviceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePluginEmailserviceItemFailure({key, error: error.message}));
    }
}
export const deletePluginEmailserviceItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePluginEmailserviceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/plugin/emailservice/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePluginEmailserviceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePluginEmailserviceItemFailure({key, error: error.message}));
    }
}


// EMAILHISTORY ACTIONS
export const fetchPluginEmailhistoryList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailhistoryListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailhistory/`, { params: info.params, headers: info.headers });
        dispatch(fetchPluginEmailhistoryListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailhistoryListFailure({key, error: error.message}));
    }
}
export const fetchPluginEmailhistoryItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailhistoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailhistory/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPluginEmailhistoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailhistoryItemFailure({key, error: error.message}));
    }
}
export const createPluginEmailhistoryItem = async (dispatch, info, key) => {
    try{
        dispatch(createPluginEmailhistoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/plugin/emailhistory/`, info.payload, { headers: info.headers });
        dispatch(createPluginEmailhistoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPluginEmailhistoryItemFailure({key, error: error.message}));
    }
}
export const updatePluginEmailhistoryItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePluginEmailhistoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/plugin/emailhistory/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePluginEmailhistoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePluginEmailhistoryItemFailure({key, error: error.message}));
    }
}
export const deletePluginEmailhistoryItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePluginEmailhistoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/plugin/emailhistory/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePluginEmailhistoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePluginEmailhistoryItemFailure({key, error: error.message}));
    }
}


// EMAILSUBSCRIPTION ACTIONS
export const fetchPluginEmailsubscriptionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailsubscriptionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailsubscription/`, { params: info.params, headers: info.headers });
        dispatch(fetchPluginEmailsubscriptionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailsubscriptionListFailure({key, error: error.message}));
    }
}
export const fetchPluginEmailsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailsubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPluginEmailsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailsubscriptionItemFailure({key, error: error.message}));
    }
}
export const createPluginEmailsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(createPluginEmailsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/plugin/emailsubscription/`, info.payload, { headers: info.headers });
        dispatch(createPluginEmailsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPluginEmailsubscriptionItemFailure({key, error: error.message}));
    }
}
export const updatePluginEmailsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePluginEmailsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/plugin/emailsubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePluginEmailsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePluginEmailsubscriptionItemFailure({key, error: error.message}));
    }
}
export const deletePluginEmailsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePluginEmailsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/plugin/emailsubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePluginEmailsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePluginEmailsubscriptionItemFailure({key, error: error.message}));
    }
}


// EMAILGROUP ACTIONS
export const fetchPluginEmailgroupList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailgroupListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailgroup/`, { params: info.params, headers: info.headers });
        dispatch(fetchPluginEmailgroupListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailgroupListFailure({key, error: error.message}));
    }
}
export const fetchPluginEmailgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/emailgroup/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPluginEmailgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailgroupItemFailure({key, error: error.message}));
    }
}
export const createPluginEmailgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(createPluginEmailgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/plugin/emailgroup/`, info.payload, { headers: info.headers });
        dispatch(createPluginEmailgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPluginEmailgroupItemFailure({key, error: error.message}));
    }
}
export const updatePluginEmailgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePluginEmailgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/plugin/emailgroup/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePluginEmailgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePluginEmailgroupItemFailure({key, error: error.message}));
    }
}
export const deletePluginEmailgroupItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePluginEmailgroupItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/plugin/emailgroup/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePluginEmailgroupItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePluginEmailgroupItemFailure({key, error: error.message}));
    }
}


// EMAIL ACTIONS
export const fetchPluginEmailList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/email/`, { params: info.params, headers: info.headers });
        dispatch(fetchPluginEmailListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailListFailure({key, error: error.message}));
    }
}
export const fetchPluginEmailItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPluginEmailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/plugin/email/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPluginEmailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPluginEmailItemFailure({key, error: error.message}));
    }
}
export const createPluginEmailItem = async (dispatch, info, key) => {
    try{
        dispatch(createPluginEmailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/plugin/email/`, info.payload, { headers: info.headers });
        dispatch(createPluginEmailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPluginEmailItemFailure({key, error: error.message}));
    }
}
export const updatePluginEmailItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePluginEmailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/plugin/email/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePluginEmailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePluginEmailItemFailure({key, error: error.message}));
    }
}
export const deletePluginEmailItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePluginEmailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/plugin/email/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePluginEmailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePluginEmailItemFailure({key, error: error.message}));
    }
}




