import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'account',
  initialState: { loading: false },
  reducers: {
    
    // USERPROFILE REDUCER FUNCTION

    // USERPROFILE FETCH LIST
    fetchAccountUserprofileListStart: stateLoadingStart,
    fetchAccountUserprofileListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_list`),
    fetchAccountUserprofileListFailure: stateError,

    // USERPROFILE FETCH ITEM
    fetchAccountUserprofileItemStart: stateLoadingStart,
    fetchAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    fetchAccountUserprofileItemFailure: stateError,

    // USERPROFILE CREATE ITEM
    createAccountUserprofileItemStart: stateLoadingStart,
    createAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    createAccountUserprofileItemFailure: stateError,

    // USERPROFILE UPDATE ITEM
    updateAccountUserprofileItemStart: stateLoadingStart,
    updateAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    updateAccountUserprofileItemFailure: stateError,

    // USERPROFILE DELETE ITEM
    deleteAccountUserprofileItemStart: stateLoadingStart,
    deleteAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_userprofile`),
    deleteAccountUserprofileItemFailure: stateError,


    // USERMESSAGEDISPATCH REDUCER FUNCTION

    // USERMESSAGEDISPATCH FETCH LIST
    fetchAccountUsermessagedispatchListStart: stateLoadingStart,
    fetchAccountUsermessagedispatchListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_list`),
    fetchAccountUsermessagedispatchListFailure: stateError,

    // USERMESSAGEDISPATCH FETCH ITEM
    fetchAccountUsermessagedispatchItemStart: stateLoadingStart,
    fetchAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_item`),
    fetchAccountUsermessagedispatchItemFailure: stateError,

    // USERMESSAGEDISPATCH CREATE ITEM
    createAccountUsermessagedispatchItemStart: stateLoadingStart,
    createAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_item`),
    createAccountUsermessagedispatchItemFailure: stateError,

    // USERMESSAGEDISPATCH UPDATE ITEM
    updateAccountUsermessagedispatchItemStart: stateLoadingStart,
    updateAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_item`),
    updateAccountUsermessagedispatchItemFailure: stateError,

    // USERMESSAGEDISPATCH DELETE ITEM
    deleteAccountUsermessagedispatchItemStart: stateLoadingStart,
    deleteAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_usermessagedispatch`),
    deleteAccountUsermessagedispatchItemFailure: stateError,


  },
});

export const {
  fetchAccountUserprofileListStart, fetchAccountUsermessagedispatchListStart,
  fetchAccountUserprofileListSuccess, fetchAccountUsermessagedispatchListSuccess,
  fetchAccountUserprofileListFailure, fetchAccountUsermessagedispatchListFailure,
  fetchAccountUserprofileItemStart, fetchAccountUsermessagedispatchItemStart,
  fetchAccountUserprofileItemSuccess, fetchAccountUsermessagedispatchItemSuccess,
  fetchAccountUserprofileItemFailure, fetchAccountUsermessagedispatchItemFailure,
  createAccountUserprofileItemStart, createAccountUsermessagedispatchItemStart,
  createAccountUserprofileItemSuccess, createAccountUsermessagedispatchItemSuccess,
  createAccountUserprofileItemFailure, createAccountUsermessagedispatchItemFailure,
  updateAccountUserprofileItemStart, updateAccountUsermessagedispatchItemStart,
  updateAccountUserprofileItemSuccess, updateAccountUsermessagedispatchItemSuccess,
  updateAccountUserprofileItemFailure, updateAccountUsermessagedispatchItemFailure,
  deleteAccountUserprofileItemStart, deleteAccountUsermessagedispatchItemStart,
  deleteAccountUserprofileItemSuccess, deleteAccountUsermessagedispatchItemSuccess,
  deleteAccountUserprofileItemFailure, deleteAccountUsermessagedispatchItemFailure
} = slice.actions;

export default slice.reducer;
