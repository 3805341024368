import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardMenu from './DashboardMenu';
import MainLayout from './MainLayout';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('tk');
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!token) {
    return <Navigate to="/login" />;
  }

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const styles = {
    privateRoute: {
      display: 'flex',
      position: 'relative',
    },
    collapseButton: {
      position: 'absolute',
      top: 5,
      left: 5,
      zIndex: 10,
      borderRadius: 50,
      height: 25,
      width: 25,
      border: 'none',
      backgroundColor: '#777',
      color: 'white',
      cursor: 'pointer',
    },
    dashboardMenu: {
      width: isCollapsed ? '0px' : '200px',
      transition: 'width 0.3s',
    },
    content: {
      flexGrow: 1,
      transition: 'padding-left 0.3s',
      backgroundColor: 'white',
      height: '100vh',
      overflowY: 'auto'
    },
  };

  return (
    <div style={styles.privateRoute}>
      <button onClick={toggleMenu} style={styles.collapseButton}>
        {isCollapsed ? '❯' : '❮'}
      </button>
      <div style={styles.dashboardMenu}>
        <DashboardMenu />
      </div>
      <main style={styles.content}>
        {children}
      </main>
    </div>
  );
}

export default PrivateRoute;
