

const faqs = [
  {
    question: 'How does the free trial work?',
    answer: 'The free trial allows you to use all the features of our platform for 30 days without any cost.',
  },
  {
    question: 'Can I upgrade my plan later?',
    answer: 'Yes, you can upgrade or downgrade your plan at any time from your account settings.',
  },
  {
    question: 'Is there any contract or commitment?',
    answer: 'No, there is no contract or long-term commitment. You can cancel your subscription at any time.',
  },
];

const FAQItem = ({ question, answer }) => (
  <div style={styles.faqItem}>
    <h4 style={styles.faqQuestion}>{question}</h4>
    <p style={styles.faqAnswer}>{answer}</p>
  </div>
);

const Faq = () => {
  return(
    <section id="faq" style={styles.faqSection}>
      <h2 style={styles.sectionTitle}>Frequently Asked Questions</h2>
      <div style={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </section>
  )
}

const styles = {
  sectionTitle: {
    fontSize: '28px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  faqSection: {
    padding: '50px 20px',
  },
  faqContainer: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  faqItem: {
    marginBottom: '20px',
  },
  faqQuestion: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  faqAnswer: {
    fontSize: '16px',
    marginTop: '10px',
  }
}

export default Faq
