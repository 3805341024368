import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";
import Modal from "components/Modal";
import { configurePluginEmailgroup, configurePluginEmail } from "services/access/plugin";

const ManageEmails = ({}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configurePluginEmail(utils, dispatch, state);
  const emailGroupConfiguration = configurePluginEmailgroup(utils, dispatch, state);

  const [newGroupName, setNewGroupName] = useState();
  const [newGroupEmailIdList, setNewGroupEmailIdList] = useState([]);

  const [searchEmailValue, setSearchEmailValue] = useState({});
  const [searchEmailValueAddNewForm, setSearchEmailValueAddNewForm] = useState();
  const [isModalOpen, setIsModalOpen] = useState({});
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [emails, setEmails] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(()=>{
    action.fetchEmailList()
  },[])

  useEffect(()=>{
    if (data?.email_fetch_list) {
      setEmails(data.email_fetch_list)
      emailGroupConfiguration.action.fetchEmailgroupList()
    }
  },[data?.email_fetch_list])
  useEffect(()=>{
    if (emailGroupConfiguration?.data?.emailgroup_fetch_list) {
      setGroups(emailGroupConfiguration.data.emailgroup_fetch_list)
    }
  },[emailGroupConfiguration?.data?.emailgroup_fetch_list])

  const addEmail = () => {
    if (!newEmailName.trim() || !newEmailEmail.trim()) {
      alert('Please enter both name and email for the new email.');
      return;
    }

    const newEmailId = Math.max(...emails.map(i => i.id)) + 1;
    const newEmail = {
      name: newEmailName.trim(),
      email: newEmailEmail.trim(),
    };

    setEmails([...emails, {...newEmail, id: newEmailId}]);
    action.createEmailItem(newEmail)
    setNewEmailName('');
    setNewEmailEmail('');
    setTimeout(()=> action.fetchEmailList(), 2000)
  };

  const [newEmailName, setNewEmailName] = useState('');
  const [newEmailEmail, setNewEmailEmail] = useState('');

  const deleteEmail = (id) => {
    action.deleteEmailItem(id)
    setEmails(prevEmails => prevEmails.filter(email => email.id !== id));
  };

  const removeGroup = (groupId) => {
    setGroups(groups.filter(group => group.id !== groupId));
    emailGroupConfiguration.action.deleteEmailgroupItem(groupId)
  };

  const addGroup = () => {
    const fakeId = Math.max(groups.map(i=>i.id)) + 1
    const new_object = {name: newGroupName || 'No name', emails: newGroupEmailIdList}
    setGroups([...groups, {...new_object, id: fakeId}]);
    emailGroupConfiguration.action.createEmailgroupItem(new_object)
    setIsAddModalOpen(false)
    setNewGroupName()
    setNewGroupEmailIdList([])
    setTimeout(()=> emailGroupConfiguration.action.fetchEmailgroupList(), 2000)
  };

  const timeoutRefGroupName = useRef(null);

  const saveNewGroupNameInfo = (name, group_id) => {
    emailGroupConfiguration.action.updateEmailgroupItem(group_id, {name})
  }
  const saveNewGroupEmailsInfo = (emails, group_id) => {
    emailGroupConfiguration.action.updateEmailgroupItem(group_id, {emails})
  }

  const updateEmailsInGroup = (group, email, g_idx) => {
    const group_emails = group.emails
    let new_group_emails = []
    const index_of_item = group_emails.indexOf(email.id)
    if (index_of_item > -1) {
      new_group_emails = group_emails.filter((i, idx)=>idx!==index_of_item)
    } else {
      new_group_emails = [...group_emails, email.id]
    }
    saveNewGroupEmailsInfo(new_group_emails, group.id)
    setGroups(groups.map((i, idx)=> idx === g_idx ? {...groups[idx], emails: new_group_emails} : i))
  }

  const filteredEmailAddNewGroupForm = emails
    .filter(email => {
      const searchValue = (searchEmailValueAddNewForm || '').toLowerCase();
      const emailName = email.name.toLowerCase();
      const emailAddress = email.email.toLowerCase();
      return emailName.includes(searchValue) || emailAddress.includes(searchValue);
    })

  return (
    <div style={styles.container}>
      <div style={{maxWidth: 800, margin: 'auto', padding: 20}}>
        <h2 style={styles.header}>Manage Emails</h2>
        <div style={{...styles.emails, ...styles.groupItem, marginBottom: 50}}>
          <h3 style={styles.subHeader}>Email List</h3>
          <div style={styles.emailActions}>
            <input
              type="text"
              placeholder="Enter Person Name"
              value={newEmailName}
              onChange={(e) => setNewEmailName(e.target.value)}
              style={styles.input}
            />
            <input
              type="text"
              placeholder="Enter Email"
              value={newEmailEmail}
              onChange={(e) => setNewEmailEmail(e.target.value)}
              style={styles.input}
            />
            <button style={styles.button} onClick={addEmail}>
              Add Entry
            </button>
          </div>
          <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>Name</th>
                  <th style={styles.th}>Email</th>
                  <th style={styles.th}>Action</th>
                </tr>
              </thead>
              <tbody>
                {emails.map((email) => (
                  <tr key={email.id}>
                    <td style={styles.td}>{email.name}</td>
                    <td style={styles.td}>{email.email}</td>
                    <td style={styles.td}>
                      <button style={styles.deleteButton} onClick={() => deleteEmail(email.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {emails.length === 0 && <div style={{padding: 10}}>No email exists</div>}
            </table>
          </div>
        </div>
        <div style={styles.groups}>
          <h3 style={styles.subHeader}>Groups<button style={{...styles.button, marginLeft: 20}} onClick={() => setIsAddModalOpen(true)}>Add</button></h3>
          {groups.length === 0 && <div>No group exists</div>}
          <Modal isOpen={isAddModalOpen} setIsOpen={(value)=>setIsAddModalOpen(value)}>
            <p><b>Group Name</b></p>
            <input
              placeholder='Group Name'
              type="text"
              style={{border: '1px solid #aaa', marginBottom: 5, padding: 5}}
              onChange={e=>setNewGroupName(e.target.value)}
              value={newGroupName}
            />
            <p><b>Emails</b></p>
            <input
              placeholder='Search Here'
              type="text"
              style={{border: '1px solid #aaa', marginBottom: 5, padding: 5}}
              onChange={e=>{setSearchEmailValueAddNewForm(e.target.value)}}
              value={searchEmailValueAddNewForm}
            />
            <div style={{ maxHeight: 100, overflowY: 'auto', marginRight: 10, padding: 10 }}>
              {
                filteredEmailAddNewGroupForm
                .map((email) => (
                  <div key={email.id}>
                    <input
                      type="checkbox"
                      checked={newGroupEmailIdList.find((emailId) => email.id === emailId)}
                      onChange={()=>{
                        let new_group_emails = []
                        const index_of_item = newGroupEmailIdList.indexOf(email.id)
                        if (index_of_item > -1) {
                          new_group_emails = newGroupEmailIdList.filter((i, idx)=>idx!==index_of_item)
                        } else {
                          new_group_emails = [...newGroupEmailIdList, email.id]
                        }
                        setNewGroupEmailIdList(new_group_emails)
                      }}
                      style={{marginRight: 10}}
                    />
                    <span>{email.name} - </span>
                    <span>{email.email}</span>
                  </div>
              ))}
              {filteredEmailAddNewGroupForm.length === 0 && <small>No entry found.</small>}
            </div>
            <button style={{...styles.button, marginTop: 10}} onClick={addGroup}>Add Group</button>
          </Modal>
          <ul style={styles.groupList}>
            {groups.map((group, g_idx) => {
              const filteredEmail = emails
                .filter(email => {
                  const searchValue = (searchEmailValue[group.id] || '').toLowerCase();
                  const emailName = email.name.toLowerCase();
                  const emailAddress = email.email.toLowerCase();
                  return emailName.includes(searchValue) || emailAddress.includes(searchValue);
                })
              return(
              <li key={group.id} style={styles.groupItem}>
                <strong>{group.name}</strong> ({group.emails.length} emails)
                <button style={styles.removeGroupButton} onClick={() => removeGroup(group.id)}>
                  Remove Group
                </button>
                <button style={styles.updateGroupButton} onClick={() => setIsModalOpen({[group.id]: true})}>
                  View & Update Group
                </button>
                <table style={{borderCollapse: 'collapse', marginTop: 10}}>
                  <thead>
                    <tr>
                      <th style={styles.th}>Name</th>
                      <th style={styles.th}>Email</th>
                      <th style={styles.th}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {group.emails.length === 0 && <div style={{padding: 10}}>No email exists in this group.</div>}
                  {group.emails.map((emailId) => {
                    const email_item = emails.find((email) => email.id === emailId)
                    return (
                      <tr style={styles.tr} key={emailId} style={styles.memberItem}>
                        <td style={styles.td}>
                          {email_item?.name}
                        </td>
                        <td style={styles.td}>
                          {email_item?.email}
                        </td>
                        <td style={styles.td}>
                          <button style={styles.removeMemberButton} onClick={() => updateEmailsInGroup(group,email_item,g_idx)}>
                            Remove
                          </button>
                        </td>
                      </tr>
                  )})}
                  </tbody>
                </table>
                <Modal isOpen={isModalOpen[group.id]} setIsOpen={(value)=>setIsModalOpen({[group.id]:value})}>
                  <p><b>Group Name</b></p>
                  <input
                    placeholder='Group Name'
                    type="text"
                    style={{border: '1px solid #aaa', marginBottom: 5, padding: 5}}
                    onChange={e=>{
                      setGroups(
                        groups.map((item, idx)=>(idx === g_idx) ? {...item, name: e.target.value} : item)
                      )
                      if (timeoutRefGroupName.current) clearTimeout(timeoutRefGroupName.current)
                      timeoutRefGroupName.current = setTimeout(() => saveNewGroupNameInfo(e.target.value, group.id), 1000);
                      return () => clearTimeout(timeoutRefGroupName.current);
                    }}
                    value={group.name}
                  />
                  <p><b>Emails</b></p>
                  <input
                    placeholder='Search Here'
                    type="text"
                    style={{border: '1px solid #aaa', marginBottom: 5, padding: 5}}
                    onChange={e=>{setSearchEmailValue({[group.id]: e.target.value})}}
                    value={searchEmailValue[group.id]}
                  />
                  <div style={{ maxHeight: 100, overflowY: 'auto', marginRight: 10, padding: 10 }}>
                    {
                      filteredEmail
                      .map((email) => (
                      <div key={email.id}>
                        <input
                          type="checkbox"
                          checked={group.emails.find((emailId) => email.id === emailId)}
                          onChange={()=>updateEmailsInGroup(group,email,g_idx)}
                          style={{marginRight: 10}}
                        />
                        <span>{email.name} - </span>
                        <span>{email.email}</span>
                      </div>
                    ))}
                    {filteredEmail.length === 0 && <small>No entry found.</small>}
                  </div>
                </Modal>
              </li>
            )})}
          </ul>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontSize: 13,
    margin: 'auto',
    height: '100vh',
    overflowY: 'auto'
  },
  header: {
    textAlign: 'center',
    color: '#333',
  },
  subHeader: {
    color: '#555',
  },
  emails: {
    marginBottom: '20px',
  },
  groups: {
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  input: {
    padding: '8px',
    margin: '5px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '8px 12px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  deleteButton: {
    padding: '4px 8px',
    backgroundColor: '#ba2c2c',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  emailActions: {
    marginTop: '10px',
  },
  groupActions: {
    marginBottom: '20px',
  },
  groupList: {
    listStyle: 'none',
    padding: '0',
  },
  groupItem: {
    padding: '10px 0',
    borderBottom: '1px solid #ccc',
  },
  removeGroupButton: {
    marginLeft: '10px',
    padding: '4px 8px',
    backgroundColor: '#ba2c2c',
    color:'#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  updateGroupButton: {
    marginLeft: '10px',
    padding: '4px 8px',
    backgroundColor: '#007BFF',
    color:'#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  memberItem: {
    marginBottom: '5px',
  },
  removeMemberButton: {
    marginLeft: '10px',
    padding: '2px 6px',
    backgroundColor: '#ba2c2c',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  th: {
    border: '1px solid #ccc',
    padding: '10px',
    background: '#f4f4f4',
    cursor: 'pointer',
    position: 'sticky',
    top: 0
  },
  td: {
    border: '1px solid #ccc',
    padding: '10px',
  },
};

export default ManageEmails;
