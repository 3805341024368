import React, {useState, useEffect, Suspense, lazy} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import SendEmail from './pages/SendEmail';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import ManageContacts from './pages/ManageContacts';
import EmailHistory from './pages/EmailHistory';
import EmailVerification from './pages/EmailVerification';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import './App.css'
import Provider from "services/api-data";
import MainLayout from "components/MainLayout";
import Features from "components/Features";
import Pricing from "components/Pricing";
import Faq from "components/Faq";
import Contact from "components/Contact";
import NotFound from "pages/NotFound";
import MergePdf from "pages/pdf/MergePdf";
import PdfToImage from "pages/pdf/PdfToImage";
import ImagesToPdf from "pages/pdf/ImagesToPdf";
import SplitPdf from "pages/pdf/SplitPdf";
import PdfTextExtraction from "pages/pdf/PdfTextExtraction";
import AddWatermarkText from "pages/pdf/AddWatermarkText";
import AddImageToPdfPage from "pages/pdf/AddImageToPdfPage";

import Test from "pages/Test";

const BASE_URLS = {
  development: "http://localhost:8000/",
  production: "https://superadmin.sushibelgium.be/",
};

export const API_BASE_URL = BASE_URLS[process.env.NODE_ENV];

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  localStorage.setItem("FABU", API_BASE_URL)
  return null;
};

function App() {
  return (
    <Provider>
        <Router>
            <Suspense fallback={<div>Loading . . .</div>}>
              <ScrollToTop />
                <Routes>
                  <Route path="/test" element={<Test />} />
                  
                  <Route path="/" element={<MainLayout><LandingPage /></MainLayout>} />
                  <Route path="/login" element={<MainLayout><Login /></MainLayout>} />
                  <Route path="/register" element={<MainLayout><Register /></MainLayout>} />
                  <Route path="/verify/:key" element={<MainLayout><EmailVerification/></MainLayout>} />
                  <Route path="/reset-password/:key" element={<MainLayout><ResetPassword/></MainLayout>} />

                  <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                  <Route path="/send-email" element={<PrivateRoute><SendEmail /></PrivateRoute>} />
                  <Route path="/manage-contacts" element={<PrivateRoute><ManageContacts /></PrivateRoute>} />
                  <Route path="/email-history" element={<PrivateRoute><EmailHistory /></PrivateRoute>} />
                  <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>} />
                  <Route path="/available-templates" element={<PrivateRoute><SendEmail availableTemplates/></PrivateRoute>} />
                  <Route path="/my-templates" element={<PrivateRoute><SendEmail myTemplates/></PrivateRoute>} />

                  <Route path="/features" element={<PrivateRoute><Features/></PrivateRoute>} />
                  <Route path="/pricing" element={<PrivateRoute><Pricing/></PrivateRoute>} />
                  <Route path="/faq" element={<PrivateRoute><Faq/></PrivateRoute>} />
                  <Route path="/contact" element={<PrivateRoute><Contact/></PrivateRoute>} />

                  <Route path="/pdf/merge-pdf" element={<MainLayout><MergePdf/></MainLayout>} />
                  <Route path="/pdf/pdf-to-image" element={<MainLayout><PdfToImage/></MainLayout>} />
                  <Route path="/pdf/images-to-pdf" element={<MainLayout><ImagesToPdf/></MainLayout>} />
                  <Route path="/pdf/split-pdf" element={<MainLayout><SplitPdf/></MainLayout>} />
                  <Route path="/pdf/pdf-text-extraction" element={<MainLayout><PdfTextExtraction/></MainLayout>} />
                  <Route path="/pdf/add-watermark-text" element={<MainLayout><AddWatermarkText/></MainLayout>} />
                  <Route path="/pdf/add-image-to-pdf-pages" element={<MainLayout><AddImageToPdfPage/></MainLayout>} />

                  <Route path="*" element={<NotFound/>} />
                </Routes>
            </Suspense>
        </Router>
    </Provider>
  );
}

export default App;
