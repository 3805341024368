import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { configurePluginEmailgroup, configurePluginEmailhistory, configurePluginEmailsubscription } from "services/access/plugin";
import { configureAccountUserprofile } from "services/access/account";
import { configurePluginEmailservice } from "services/access/plugin";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "services/api-data/src/App";

const displayDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
    // timeZoneName: 'short'
  };
  return date.toLocaleDateString('en-US', options);
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const navigate = useNavigate()
  const { initialize, data, action } = configurePluginEmailgroup(utils, dispatch, state);
  const configProfile = configureAccountUserprofile(utils, dispatch, state);
  const configHistory = configurePluginEmailhistory(utils, dispatch, state);
  const configEmailService = configurePluginEmailservice(utils, dispatch, state);
  const configEmailSubscription = configurePluginEmailsubscription(utils, dispatch, state);

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    if (user_id) {
      configProfile.action.fetchUserprofileItem(user_id);
      configEmailService.action.fetchEmailserviceItem(user_id);
      configEmailSubscription.action.fetchEmailsubscriptionItem(user_id);
    }
  }, [user_id]);

  useEffect(()=>{
    action.fetchEmailgroupList()
    configHistory.action.fetchEmailhistoryList()
  }, [])

  const email_quota_left = configEmailSubscription?.data?.emailsubscription_fetch_item?.email_quota || 0
  const total_emails = configHistory?.data?.emailhistory_fetch_list?.length
  const success_emails = configHistory?.data?.emailhistory_fetch_list?.filter(i=>i.status==='success').length
  const viewed_emails = configHistory?.data?.emailhistory_fetch_list?.filter(i=>i.viewed>0).length
  const email_expiry_date = configEmailSubscription?.data?.emailsubscription_fetch_item?.premium_expiry || '...'
  const has_premium_expired = configEmailSubscription?.data?.emailsubscription_fetch_item?.is_premium === undefined ? false : !(configEmailSubscription?.data?.emailsubscription_fetch_item?.is_premium)

  return (
    <div style={{ height: '100vh', overflowY: 'auto', fontSize: 13 }}>
      {has_premium_expired && <div style={{padding: 10, fontSize: 14, color: 'white', background:'linear-gradient(135deg, #c3092b 0%, #000 100%)'}}>Your premium has expired. <button style={{backgroundColor: 'rgba(0,0,0,0.1)', border: 'none', cursor: 'pointer', color: 'white', textDecoration: 'underline'}} onClick={()=>navigate('/pricing')}>Get a subscription to renew.</button></div>}
      {configEmailSubscription?.data?.emailsubscription_fetch_item?.email_quota === 0 && <div style={{padding: 10, fontSize: 14, color: 'white', background:'linear-gradient(135deg, #c3092b 0%, #000 100%)', paddingLeft: 40}}>Your email quota is down to 0. You cannot send any email. <button style={{backgroundColor: 'rgba(0,0,0,0.1)', border: 'none', cursor: 'pointer', color: 'white', textDecoration: 'underline'}} onClick={()=>navigate('/pricing')}>Get a subscription to keep sending emails.</button></div>}
      <h1 style={{textAlign: 'center'}}>Overview</h1>
      <div style={{padding: 20}}>
        {(configProfile?.data?.userprofile_fetch_item?.is_email_verified === 'NO') && <div style={{ border: '1px solid #c3092b', padding: 10, marginBottom: 20, borderRadius: 5, backgroundColor: '#c3092b22', width: 'fit-content' }}>
          <div style={{color: '#c3092b'}}><b>Verify your account!</b>{' '}You will not be able to send emails with unverified account.</div>
          <div style={{color: '#c3092b'}}>Go to your <button style={{backgroundColor: 'rgba(255,255,255,0.9)', color:'#c3092b', border: '1px solid #c3092b', borderRadius: 3, cursor: 'pointer'}} onClick={()=>navigate("/profile")}><b>profile settings</b></button> and click on <b>Verify through email</b></div>
        </div>}
        <section style={{ marginBottom: '20px', backgroundColor: '#eee', borderRadius: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <div style={{ flex: 1, padding: '20px', borderRadius: '4px' }}>
              <div style={{borderRight: '1px solid #DDD'}}>
                <h2>Profile Information</h2>
                <p><b>Name</b>: {!configProfile?.data?.userprofile_fetch_item && 'Loading...'}{configProfile?.data?.userprofile_fetch_item?.first_name} {configProfile?.data?.userprofile_fetch_item?.last_name}</p>
                <p><b>Email</b>: {!configProfile?.data?.userprofile_fetch_item && 'Loading...'}{configProfile?.data.userprofile_fetch_item?.email}</p>
                <button style={styles.button} onClick={()=>navigate('/profile')}>Edit Profile</button>
              </div>
            </div>
            <div style={{ flex: 1, padding: '20px', borderRadius: '4px' }}>
              <div style={{borderRight: '1px solid #DDD'}}>
                <h2>Email Setup</h2>
                <p><b>Company Name:</b> {!configEmailService?.data?.emailservice_fetch_item && 'Loading...'}{configEmailService?.data?.emailservice_fetch_item?.sender_company_name}</p>
                <button style={styles.button} onClick={()=>navigate('/profile')}>Edit Profile</button>
              </div>
            </div>
            <div style={{ flex: 1, padding: '20px', borderRadius: '4px' }}>
              <h2>Email Stats </h2>
              <div style={{color: '#c3092b'}}>{has_premium_expired ? 'Your premium has Expired' : ''}</div>
              <p>{!configHistory?.data?.emailhistory_fetch_list && <span>Loading...</span>}</p>
              {configHistory?.data?.emailhistory_fetch_list && <>
                <p><b>Available Emails</b>: {email_quota_left} (Expires on: {displayDate(email_expiry_date)})</p>
                <p><b>Email Sent</b>: {total_emails}</p>
                <p><b>Email Delivered</b>: {success_emails}</p>
                <p><b>Email Opened (confirmed only)</b>: {viewed_emails} ({viewed_emails === 0 ? '0' : (viewed_emails/success_emails).toFixed(0)}%)</p>
              </>}
            </div>
          </div>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <div style={{display: 'flex', padding: '20px', borderRadius: '4px'}}>
            <div style={{flex: 5, backgroundColor: '#cde', padding: 20, borderRadius: 20}}>
              <h2>Compose Email</h2>
              <div>
                <p>Compose emails from over 50+ templates and send them to your clients.</p>
                <button style={styles.button} onClick={()=>navigate('/send-email')}>Compose New Email</button>
              </div>
            </div>
            <div style={{flex: 1}}/>
            <div style={{flex: 5, backgroundColor: '#eee', padding: 20, borderRadius: 20}}>
              <h2>Email Group</h2>
              <div>
                <h3>Groups Overview</h3>
                {!data?.emailgroup_fetch_list && <div>Loading...</div>}
                {data?.emailgroup_fetch_list?.length === 0 && <div>You do not have any email group.</div>}
                <ul>
                  {data?.emailgroup_fetch_list?.map((i,idx)=>{
                    return(
                      <li key={idx}>{i.name}</li>
                    )
                  })}
                </ul>
                <button style={styles.button} onClick={()=>navigate('/manage-contacts')}>Manage Email Groups</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const styles = {
  button: {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    margin: '5px 0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Dashboard;
