import React, { useState } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

const InsertImagesIntoPDF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageOptions, setImageOptions] = useState({
    pages: 'all',
    position: 'center',
    width: 100,
    height: 100,
    pageNumber: ''
  });
  const [loading, setLoading] = useState(false);

  const handlePdfFileChange = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const handleImageFilesChange = (event) => {
    setImageFiles(Array.from(event.target.files));
  };

  const handleOptionChange = (event) => {
    setImageOptions({
      ...imageOptions,
      [event.target.name]: event.target.value
    });
  };

  const insertImages = async () => {
    if (!pdfFile || imageFiles.length === 0) {
      alert('Please select a PDF file and at least one image.');
      return;
    }

    setLoading(true);

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const pdfDoc = await PDFDocument.load(fileReader.result);

        const positionMap = {
          'center': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: (pageWidth - imgWidth) / 2,
            y: (pageHeight - imgHeight) / 2
          }),
          'center top': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: (pageWidth - imgWidth) / 2,
            y: pageHeight - imgHeight
          }),
          'center bottom': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: (pageWidth - imgWidth) / 2,
            y: 0
          }),
          'top left': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: 0,
            y: pageHeight - imgHeight
          }),
          'top right': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: pageWidth - imgWidth,
            y: pageHeight - imgHeight
          }),
          'bottom left': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: 0,
            y: 0
          }),
          'bottom right': (pageWidth, pageHeight, imgWidth, imgHeight) => ({
            x: pageWidth - imgWidth,
            y: 0
          })
        };

        const pageIndexes = imageOptions.pages === 'all' ? pdfDoc.getPages().map((_, index) => index) : [parseInt(imageOptions.pageNumber) - 1];
        const pages = pdfDoc.getPages();

        for (let i = 0; i < imageFiles.length; i++) {
          const imgFile = imageFiles[i];
          const imgBytes = await imgFile.arrayBuffer();
          let img;
          if (imgFile.type === 'image/png') {
            img = await pdfDoc.embedPng(imgBytes);
          } else if (imgFile.type === 'image/jpeg' || imgFile.type === 'image/jpg') {
            img = await pdfDoc.embedJpg(imgBytes);
          } else {
            console.warn('Unsupported image type:', imgFile.type);
            continue;
          }

          const { width: imgWidth, height: imgHeight } = img.size();

          for (const pageIndex of pageIndexes) {
            if (pages[pageIndex]) {
              const page = pages[pageIndex];
              const { width: pageWidth, height: pageHeight } = page.getSize();
              const { x, y } = positionMap[imageOptions.position](pageWidth, pageHeight, parseFloat(imageOptions.width), parseFloat(imageOptions.height));
              page.drawImage(img, {
                x,
                y,
                width: parseFloat(imageOptions.width),
                height: parseFloat(imageOptions.height),
              });
            } else {
              console.warn(`Page index ${pageIndex} is out of bounds`);
            }
          }
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(blob, 'modified.pdf');
        setLoading(false);
      } catch (error) {
        console.error('Error inserting images into PDF:', error);
        setLoading(false);
      }
    };
    fileReader.readAsArrayBuffer(pdfFile);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  };

  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
    fontSize: '24px'
  };

  const inputStyle = {
    margin: '10px 0',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc'
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: loading ? '#ccc' : '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: loading ? 'not-allowed' : 'pointer',
    margin: '10px',
    fontSize: '16px',
    transition: 'background-color 0.3s ease'
  };

  const loaderStyle = {
    marginTop: '20px',
    fontSize: '20px',
    color: '#28a745'
  };

  const labelStyle = {
    marginRight: '10px',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Insert Images into PDF</h1>
      <label style={labelStyle}>Choose PDF:</label>
      <input
        type="file"
        accept="application/pdf"
        onChange={handlePdfFileChange}
        style={inputStyle}
      />
      <label style={labelStyle}>Choose Images:</label>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        multiple
        onChange={handleImageFilesChange}
        style={inputStyle}
      />
      <div style={inputStyle}>
        <label>
          <input
            type="radio"
            name="pages"
            value="all"
            checked={imageOptions.pages === 'all'}
            onChange={handleOptionChange}
          />
          Add to All Pages
        </label>
        <label style={{ marginLeft: '20px' }}>
          <input
            type="radio"
            name="pages"
            value="number"
            checked={imageOptions.pages === 'number'}
            onChange={handleOptionChange}
          />
          Add to Page Number
        </label>
        {imageOptions.pages === 'number' && (
          <input
            type="number"
            name="pageNumber"
            value={imageOptions.pageNumber}
            onChange={handleOptionChange}
            style={{ ...inputStyle, width: '60px', marginLeft: '10px' }}
            placeholder="Page"
          />
        )}
      </div>
      <select
        name="position"
        value={imageOptions.position}
        onChange={handleOptionChange}
        style={inputStyle}
      >
        <option value="center">Center</option>
        <option value="center top">Center Top</option>
        <option value="center bottom">Center Bottom</option>
        <option value="top left">Top Left</option>
        <option value="top right">Top Right</option>
        <option value="bottom left">Bottom Left</option>
        <option value="bottom right">Bottom Right</option>
      </select>
      <div style={inputStyle}>
        <label style={labelStyle}>Width:</label>
        <input
          type="number"
          name="width"
          value={imageOptions.width}
          onChange={handleOptionChange}
          style={{ width: '60px', marginRight: '20px' }}
        />
        <label style={labelStyle}>Height:</label>
        <input
          type="number"
          name="height"
          value={imageOptions.height}
          onChange={handleOptionChange}
          style={{ width: '60px' }}
        />
      </div>
      <button onClick={insertImages} style={buttonStyle} disabled={loading}>
        Insert Images
      </button>
      {loading && <div style={loaderStyle}>Loading...</div>}
    </div>
  );
};

export default InsertImagesIntoPDF;
